import React from 'react';
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import LoginForm from "./loginForm/LoginForm";
import img from "../../../assets/logo/img.png";

// Css
import "./Login.css";

function Login() {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Redirect to the sign up page
     */
    const navigate = useNavigate();

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Redirect to the sign up page
     */
    const redirectSignUp = () => {
        navigate("/signup");
    }

    /**
     * Redirect to the sign up page
     */
    const redirectResetPasword = () => {
        navigate("/reset-password");
    }

    /**************************************************************************
     * Styles
     *************************************************************************/

    /**
     * Get button style
     */
    const buttonStyle = {
        color: '#426e86',
        width: '100%',
        height: '50px',
        marginTop: '7%',
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#d9d9d9',
        },
    }

    /**
     * Get button style 2
     */
    const buttonStyle2 = {
        color: '#426e86',
        width: '100%',
        height: '50px',
        marginTop: '7%',
        backgroundColor: '#dadada',
        '&:hover': {
            backgroundColor: '#b7b7b7',
        },
    }

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div className="mainContentLogin">
            <img className="imgLogoLogin" src={img} alt="Description de"/>
            <div className="signupText">
                <div className="titleformsignup">Connexion</div>
                <LoginForm/>
                <div className="footerForm">
                    <Button
                        variant="outlined"
                        sx={buttonStyle}
                        onClick={redirectSignUp}
                    >
                        Pas encore de compte ?
                    </Button>
                    <Button
                        variant="outlined"
                        sx={buttonStyle2}
                        onClick={redirectResetPasword}
                    >
                        mot de passe oublié ?
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Login;