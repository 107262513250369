import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CropFreeIcon from '@mui/icons-material/CropFree';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactPageIcon from '@mui/icons-material/ContactPage';

// Css
import "./BottomNavBar.css";

function BottomNavBar() {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Navigate to another page
     */
    const navigate = useNavigate();

    /**
     * Get current location
     */
    const location = useLocation();

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Redirect to card preview page
     */
    const redirectCardPreview = () => {
        if(location.pathname === "/home/myCard") {
            navigate("/home/myCardPro");
        } else if (location.pathname === "/home/myCardPro") {
            navigate("/home/myCard");
        } else {
            navigate("/home/myCard");
        }
    };

    /**
     * Redirect to QR code scanner page
     */
    const redirectQrCodeScanner = () => {
        if (location.pathname !== "/home/qrcodeScanner") {
            navigate("/home/qrcodeScanner");
        }
    };

    /**
     * Redirect to contact page
     */
    const redirectContact = () => {
        if (location.pathname !== "/home/contact") {
            navigate("/home/contact");
        }
    };

    /**
     * Redirect to setting page
     */
    const redirectSetting = () => {
        if (location.pathname !== "/home/setting") {
            navigate("/home/setting");
        }
    }

    /**************************************************************************
     * Styles
     *************************************************************************/

    /**
     * Get button style
     *
     * @param path
     */
    const getButtonStyle = (path) => ({
        color: location.pathname === path ? '#426e86' : '#426e86',
        backgroundColor: location.pathname === path ? '#424242' : '#1c1c1c',
        width: '100%',
        height: '100%',
    });

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div>
            <div className="navBar">
                <Button onClick={redirectSetting} sx={getButtonStyle("/home/setting")}>
                    <SettingsIcon/>
                </Button>
                <Button onClick={redirectContact} sx={getButtonStyle("/home/contact")}>
                    <ContactPageIcon/>
                </Button>
                <Button onClick={redirectCardPreview} sx={getButtonStyle("/home/myCard")}>
                    <CreditCardIcon/>
                </Button>
                <Button onClick={redirectQrCodeScanner} sx={getButtonStyle("/home/qrcodeScanner")}>
                    <CropFreeIcon/>
                </Button>
            </div>
            <div style={{ height: '70px' }}>
            </div>
        </div>
    );
}

export default BottomNavBar;
