export default class Address {
    constructor(addressType, city, country, state, street, street2, street3, streetNumber, zip) {
        this.addressType = addressType;
        this.city = city;
        this.country = country;
        this.state = state;
        this.street = street;
        this.street2 = street2;
        this.street3 = street3;
        this.streetNumber = streetNumber;
        this.zip = zip;
    }

    getAddressType() {
        return this.addressType;
    }

    setAddressType(addressType) {
        this.addressType = addressType;
    }

    getCity() {
        return this.city;
    }

    setCity(city) {
        this.city = city;
    }

    getCountry() {
        return this.country;
    }

    setCountry(country) {
        this.country = country;
    }

    getState() {
        return this.state;
    }

    setState(state) {
        this.state = state;
    }

    getStreet() {
        return this.street;
    }

    setStreet(street) {
        this.street = street;
    }

    getStreet2() {
        return this.street2;
    }

    setStreet2(street2) {
        this.street2 = street2;
    }

    getStreet3() {
        return this.street3;
    }

    setStreet3(street3) {
        this.street3 = street3;
    }

    getStreetNumber() {
        return this.streetNumber;
    }

    setStreetNumber(streetNumber) {
        this.streetNumber = streetNumber;
    }

    getZip() {
        return this.zip;
    }

    setZip(zip) {
        this.zip = zip;
    }
}
