import React from 'react';
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";

// Css
import './NotFound.css';

function NotFound() {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Function to navigate to a different page
     */
    const navigate = useNavigate();

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Redirect to the root page
     */
    const redirectBack = () => {
        navigate(-1);
    }

    /**************************************************************************
     * Styles
     *************************************************************************/

    /**
     * Get button style
     */
    const buttonStyle = {
        color: 'white',
        width: '100%',
        height: '50px',
        backgroundColor: '#FFA500',
        '&:hover': {
            backgroundColor: '#ffb437',
        },
    }

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div className="error404-container">
            <h1 className="error404-title">404</h1>
            <p className="error404-text">Oops! The page you are looking for could not be found.</p>
            <Button
                variant="contained"
                onClick={redirectBack}
                sx={buttonStyle}
            >
                Retour
            </Button>
        </div>
    );
}

export default NotFound;