import React, {useEffect, useState} from "react";
import BottomNavBar from "../../components/navBar/bottomNavBar/BottomNavBar";
import FormFields from './components/formFields/FormFields';
import {useMutation, useQuery} from "@apollo/client";
import CURRENT_CARD_QUERY from "../../services/api/queries/CURRENT_CARD_QUERY";
import CREATE_CARD from "../../services/api/mutations/CREATE_CARD_MUTATION";
import Address from "../../models/Address";
import FormButtonSave from "./components/formButtonSave/FormButtonSave";
import MessageSaveDialog from "./components/dialog/messageSaveDialog/MessageSaveDialog";
import AddAttachementDialog from "./components/dialog/addAttachementDialog/FileUploadDialog";
import GetProfilePicture from "../../services/api/fetch/GetProfilePicture";
import sendFile from "../../services/api/fetch/SendFile";
import TopNavBar from "../../components/navBar/topNavBar/TopNavBar";
import LoadingScreen from "../../components/loadingScreen/LoadingScreen";
import {Dialog} from "@mui/material";
import MessagePopup from "../../components/messagePopup/MessagePopup";

// Css
import './VisitCardForm.css';

function VisitCardForm() {


    /**************************************************************************
     * Api calls
     *************************************************************************/

    /**
     * Get the current card from the database
     */
    const {loading, data} = useQuery(CURRENT_CARD_QUERY);

    /**
     * Refetch the current card
     */
    const {refetch} = useQuery(CURRENT_CARD_QUERY);

    /**
     * Create a new card in the database & refetch the current card
     */
    const [createCard] = useMutation(CREATE_CARD, {
        onCompleted: () => {
            setTimeout(() => {
                refetch();
            }, 500);
        }
    });

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Value of the fields name
     */
    const [nom, setNom] = useState('');

    /**
     * Value of the fields first name
     */
    const [prenom, setPrenom] = useState('');

    /**
     * Value of the fields phone
     */
    const [phone, setPhone] = useState('');

    /**
     * Value of the fields mail
     */
    const [mail, setMail] = useState('');

    /**
     * Value of the fields address
     */
    const [addresses, setAddresses] = useState(new Address('', '', '', '', '', '', '', '', ''));

    /**
     * Message to display in the dialog
     */
    const [messagePopup, setMessagePopup] = useState("");

    /**
     * Profile photo
     */
    const [profilePhoto, setProfilePhoto] = useState(null);

    /**
     * Profile picture changed
     */
    const [profilePictureChanged, setProfilePictureChanged] = useState(false);

    /**
     * Profile picture url
     */
    const [profilePictureUrl, setProfilePictureUrl] = useState(null);

    /**
     * Fields changed
     */
    const [fieldsChanged, setFieldsChanged] = useState(false);

    /**
     * Social media list
     */
    const [socialMediaList, setSocialMediaList] = useState([]);

    /**
     * Social media change
     */
    const [SocialMediaChange, setSocialMediaChange] = useState(false);

    /**
     * Website url
     */
    const [webSiteUrl, setWebSiteUrl] = useState('');

    /**
     * Logo url 1
     */
    const [logoUrl1, setLogoUrl1] = useState('');

    /**
     * Profession
     */
    const [profession, setProfession] = useState('');

    /**
     * gender
     */
    const [gender, setGender] = useState('');

    /**
     * Notes
     */
    const [notes, setNotes] = useState('');

    /**
     * Color
     */
    const [color, setColor] = useState(0);

    /**************************************************************************
     * Dialog
     *************************************************************************/

    /**
     * Boolean to open or close the dialog for the error
     */
    const [openError, setOpenError] = useState(false);

    /**
     * Open the dialog for the error
     */
    const handleOpenError = () => {
        setOpenError(true);
    };

    /**
     * Close the dialog for the error
     */
    const handleCloseError = () => {
        setOpenError(false);
    };

    /**
     * Boolean to open or close the dialog
     */
    const [open, setOpen] = useState(false);

    /**
     * Close the dialog
     */
    const handleClose = () => {
        setOpen(false);
    };

    /**
     * Open the dialog and start the timer (5s) to close it
     */
    const handleOpen = () => {
        setOpen(true);
    }

    /**
     * Boolean to open or close the dialog
     */
    const [openDialogAttachement, setOpenDialogAttachement] = useState(false);

    /**
     * Close the dialog
     */
    const handleCloseDialogAttachement = () => {
        setOpenDialogAttachement(false);
    };

    /**
     * Open the dialog and start the timer (5s) to close it
     */
    const handleOpenDialogAttachement = () => {
        setOpenDialogAttachement(true);
    }


    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Handle the change of the address fields
     * @param field
     * @param value
     */
    const handleAddressChange = (field, value) => {
        setAddresses(prevAddresses => ({
            ...prevAddresses,
            [field]: value
        }));
    };

    /**
     * Handle the change of the profile photo
     */
    const handleProfilePhotoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfilePictureChanged(true);
            setProfilePhoto(file);
        }
    };

    /**
     * Handle the creation of the card
     * @returns {Promise<void>}
     */
    const handleCreateCard = async () => {
        if (gender === '') {
            setGender("NOT_SET");
        }
        try {
            if (fieldsChanged || SocialMediaChange) {
                await createCard({
                    variables: {
                        firstName: prenom,
                        lastName: nom,
                        phone: phone,
                        city: addresses.city,
                        street: addresses.street,
                        zip: addresses.zip,
                        country: addresses.country,
                        streetNumber: addresses.streetNumber,
                        email: mail,
                        SocialMedia: socialMediaList,
                        website: {description: null, url: webSiteUrl},
                        logoUrl1: logoUrl1,
                        gender: gender,
                        notes: notes,
                        profession: profession,
                        color: hexToInt(color)
                    }
                });
                setMessagePopup("Informations sauvegardées avec succès");
                setFieldsChanged(false);
            }
            if (profilePictureChanged) {
                await sendFile(profilePhoto, "profile picture", "PROFILE");
                setProfilePictureChanged(false);
                setMessagePopup("Informations sauvegardées avec succès");
            }
        } catch (error) {
            console.error("Error creating card", error);
            setMessagePopup("Erreur lors de la sauvegarde des informations");
        }
        handleOpen();
    };

    function intToHexColor(intColor) {
        if (intColor === undefined) {
            return '#ffb437';
        }

        let hexColor = intColor.toString(16);
        while (hexColor.length < 6) {
            hexColor = '0' + hexColor;
        }
        return '#' + hexColor;
    }

    function hexToInt(hexColor) {
        if (hexColor.startsWith('#')) {
            hexColor = hexColor.slice(1);
        }
        return parseInt(hexColor, 16);
    }

    /**************************************************************************
     * UseEffect
     *************************************************************************/

    // take all data from the database and put it in the fields
    useEffect(() => {
        if (!loading && data && data.currentCard) {
            setNom(data.currentCard.lastName || '');
            setPrenom(data.currentCard.firstName || '');
            setWebSiteUrl(data.currentCard.website ? data.currentCard.website.url : '');
            setLogoUrl1(data.currentCard.logoURL1 || '');
            setProfession(data.currentCard.profession || '');
            setGender(data.currentCard.gender || '');
            setNotes(data.currentCard.notes || '');
            setColor(intToHexColor(data.currentCard.color || 0));

            if (data.currentCard.phone !== null) {
                setPhone(data.currentCard.phone[0].number || '');
            }
            if (data.currentCard.email !== null) {
                setMail(data.currentCard.email[0].address || '');
            }
            if (data.currentCard.socialMedia !== null) {
                setSocialMediaList(data.currentCard.socialMedia.map((socialMedia) => {
                    return {platform: socialMedia.platform, link: socialMedia.link};
                }));
            }
            if (data.currentCard.address) {
                setAddresses(
                    new Address(
                        data.currentCard.address.addressType || '',
                        data.currentCard.address.city || '',
                        data.currentCard.address.country || '',
                        data.currentCard.address.state || '',
                        data.currentCard.address.street || '',
                        data.currentCard.address.street2 || '',
                        data.currentCard.address.street3 || '',
                        data.currentCard.address.streetNumber || '',
                        data.currentCard.address.zip || ''
                    )
                );
            } else {
                setAddresses(
                    new Address('', '', '', '', '', '', '', '', '')
                );
            }
        }
    }, [loading, data]);

    // Get the profile picture
    useEffect(() => {
        const fetchData = async () => {
            if (data?.currentCard?.profilePicture) {
                let response = await GetProfilePicture(localStorage.getItem('username'));
                if (response) {
                    setProfilePictureUrl(response.url);
                }
            }
        };

        fetchData();
    }, [data?.currentCard?.profilePicture]);


    /**************************************************************************
     * Render
     *************************************************************************/

    // Loading
    if (loading) return <LoadingScreen/>;

    // Main render
    return (
        <div>
            <TopNavBar numbar={1}/>
            <form className="formCreateCard">
                <FormFields
                    nom={nom} setNom={setNom}
                    prenom={prenom} setPrenom={setPrenom}
                    phone={phone} setPhone={setPhone}
                    mail={mail} setMail={setMail}
                    addresses={addresses} handleAddressChange={handleAddressChange}
                    profilePhoto={profilePhoto} handleProfilePhotoChange={handleProfilePhotoChange}
                    profilePictureUrl={profilePictureUrl}
                    handleOpenDialogAttachement={handleOpenDialogAttachement}
                    setFieldsChanged={setFieldsChanged}
                    socialMediaList={socialMediaList}
                    setSocialMediaList={setSocialMediaList}
                    setSocialMediaChange={setSocialMediaChange}
                    setWebSiteUrl={setWebSiteUrl}
                    webSiteUrl={webSiteUrl}
                    setLogoUrl1={setLogoUrl1}
                    logoUrl1={logoUrl1}
                    profession={profession}
                    setProfession={setProfession}
                    notes={notes}
                    setNotes={setNotes}
                    gender={gender}
                    setGender={setGender}
                    color={color}
                    setColor={setColor}
                />
                <div style={{
                    height: '40px'
                }}>
                </div>
                <FormButtonSave handleCreateCard={handleCreateCard} fieldsChanged={fieldsChanged}
                                profilePictureChanged={profilePictureChanged}
                                socialMediaChanged={SocialMediaChange}
                />
            </form>
            <MessageSaveDialog open={open} handleClose={handleClose} messagePopup={messagePopup}/>
            <AddAttachementDialog
                open={openDialogAttachement}
                handleClose={handleCloseDialogAttachement}
                handleOpenError={handleOpenError}
            />
            <Dialog open={openError}>
                <MessagePopup
                    closePopup={handleCloseError}
                    title="Oops !"
                    message="Il s'emblait que votre fichier est trop lourd. Veuillez choisir un fichier de moins de 4 Mo."
                    messageButton="Fermer"
                    button={true}
                />
            </Dialog>
            <BottomNavBar title="Création de la carte de visite"/>
        </div>
    );
}

export default VisitCardForm;