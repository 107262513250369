import React, {useState} from 'react';
import {Autocomplete, Button, Dialog, TextField} from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import './FormButtons.css';
import SocialMediaClass from "../../../../../models/SocialMedia";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

const FormButtons = ({handleOpenDialogAttachement, setSocialMediaList, socialMediaList, setSocialMediaChange}) => {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * List of social media
     */
    const listSocialMedia = [
        {label: "FACEBOOK", icon: <FacebookIcon/>},
        {label: "INSTAGRAM", icon: <InstagramIcon/>},
        {label: "TWITTER", icon: <TwitterIcon/>},
        {label: "LINKEDIN", icon: <LinkedInIcon/>},
        {label: "YOUTUBE", icon: <YouTubeIcon/>},
    ]

    /**
     * Current social media
     */
    const [currentSocialMedia, setCurrentSocialMedia] = useState(null);

    /**
     * Media selected
     */
    const [media, setMedia] = useState(null);

    /**
     * Link of the media
     */
    const [link, setLink] = useState('');

    /**
     * Dialog open
     */
    const [open, setOpen] = useState(false);

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Close dialog and add social media
     */
    const handleClose = () => {
        if (currentSocialMedia !== null && media !== null) {
            const newSocialMediaList = [...socialMediaList];
            newSocialMediaList[currentSocialMedia] = new SocialMediaClass(media.label, link);
            setSocialMediaList(newSocialMediaList);
            setSocialMediaChange(true);
        } else if (media !== null) {
            setSocialMediaList([...socialMediaList, new SocialMediaClass(media.label, link)]);
            setSocialMediaChange(true);
        }
        setOpen(false);
    };

    /**
     * Open dialog and set the current social media
     *
     * @param index
     */
    const handleOpen = (index) => {
        if (index !== undefined) {
            setCurrentSocialMedia(index);
            setMedia({label: socialMediaList[index].platform});
            setLink(socialMediaList[index].link);
        } else {
            setCurrentSocialMedia(null);
            setMedia(null);
            setLink('');
        }
        setOpen(true);
    };

    /**
     * Delete social media
     */
    const handleDelete = () => {
        if (currentSocialMedia !== null) {
            const newSocialMediaList = [...socialMediaList];
            newSocialMediaList.splice(currentSocialMedia, 1);
            setSocialMediaList(newSocialMediaList);
            setSocialMediaChange(true);
        }
        setOpen(false);
    };

    /**************************************************************************
     * Styles
     *************************************************************************/

    /**
     * Get button style
     */
    const ButtonStyle = () => ({
        color: '#000000',
        border: '1px solid #000000',
    });

    /**
     * Get button style
     */
    const ButtonStyleOpenDialogAttachement = () => ({
        color: '#ffb437',
        backgroundColor: '#000000',
        '&:hover': {
            backgroundColor: '#262626',
        },
        padding: 1
    });

    /**
     * Get button style
     */
    const ButtonStyleCloseDialog = () => ({
        color: '#ffb437',
        backgroundColor: '#000000',
        '&:hover': {
            backgroundColor: '#262626',
        },
        marginRight: '10px'
    });

    /**
     * Get button style
     */
    const ButtonStyleDelete = () => ({
        color: '#ffb437',
        backgroundColor: '#ff0000',
        '&:hover': {
            backgroundColor: '#cc0000',
        },
    });
    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div className="mainContentButton">
            <div className="addSocialMedia">
                {socialMediaList.map((socialMedia, index) => (
                    <div key={index} style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '5px',

                    }}>
                        {React.cloneElement(
                            listSocialMedia.find(item => item.label === socialMedia.platform).icon,
                            {
                                fontSize: 'large',
                                onClick: () => handleOpen(index),
                            }
                        )}
                    </div>
                ))}
                {socialMediaList.length < listSocialMedia.length && (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 45
                    }}>
                        <Button onClick={() => handleOpen()} sx={ButtonStyle}>
                            +
                        </Button>
                    </div>
                )}
            </div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 45,
            }}>
                <Button onClick={handleOpenDialogAttachement} sx={ButtonStyleOpenDialogAttachement}>
                    <AttachFileIcon/>
                </Button>
            </div>

            <Dialog open={open} onClose={handleClose}>
                <div style={{padding: '20px'}}>
                    Média:
                    <Autocomplete
                        disablePortal
                        options={listSocialMedia.filter(
                            option => !socialMediaList.some(
                                socialMedia => socialMedia.platform === option.label
                            )
                        )}
                        getOptionLabel={(option) => option.label}
                        value={media}
                        onChange={(event, newValue) => {
                            setMedia({label: newValue.label});
                        }}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderInput={(params) => <TextField {...params} variant="outlined"/>}
                    />
                    <br/>
                    URL:
                    <TextField
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        fullWidth
                        sx={{marginBottom: '10px'}}
                    />
                    <Button onClick={handleClose} sx={ButtonStyleCloseDialog}>
                        Fermer
                    </Button>

                    <Button onClick={handleDelete} sx={ButtonStyleDelete}>
                        Supprimer
                    </Button>
            </div>
        </Dialog>
</div>
)
    ;
};

export default FormButtons;