import {gql} from "@apollo/client";

const GetAttachement = gql`
query Attachments($principle: String!){
    attachments (principle: $principle) {
        description
        fileName
        fileType
        id
        size
        timestamp
        uploadType
        temporary
    }
}

`;
export default GetAttachement;