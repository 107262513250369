import React, {useState} from 'react';
import {useLocation} from "react-router";
import {Button, Dialog} from "@mui/material";
import registerRequest from "../../services/api/fetch/ActivateAccountFetch"
import {useNavigate} from "react-router-dom";
import MessagePopup from "../../components/messagePopup/MessagePopup";

// Css
import './ActivateAccount.css';

function ActivateAccount() {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * useNavigate hook
     */
    const navigate = useNavigate();

    /**
     * useLocation hook
     *
     * @type {Location}
     */
    const location = useLocation();

    /**
     * URLSearchParams object
     *
     * @type {module:url.URLSearchParams}
     */
    const searchParams = new URLSearchParams(location.search);

    /**
     * Get email from URL
     *
     * @type {string}
     */
    const email = searchParams.get('email');

    /**
     * Get token from URL
     *
     * @type {string}
     */
    const token = searchParams.get('token');

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Open the dialog and start the timer (5s) to close it
     */
    const handleOpen = () => {
        setOpen(true);
        setTimeout(function () {
            handleClose();
            navigate('/');
        }, 5000);
    }

    /**
     * Activate the account with the email and token from the URL
     *
     * @returns {Promise<void>}
     */
    const activateAccount = async () => {
        let response = await registerRequest(email, token);
        if (response.status === 200) {
            handleOpen();
        } else {
            alert("Error !");
        }
    }

    /**************************************************************************
     * Dialog
     *************************************************************************/

    /**
     * Boolean to open or close the dialog
     */
    const [open, setOpen] = useState(false);

    /**
     * Close the dialog
     */
    const handleClose = () => {
        setOpen(false);
    };

    /**************************************************************************
     * Styles
     *************************************************************************/

    /**
     * Style for the button
     */
    const buttonStyle = {
        color: 'white',
        backgroundColor: '#426e86',
        '&:hover': {
            backgroundColor: '#61869a',
        },
    }

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div className="content-container">
            <div className="activate-account-container">
                <p>Activer votre compte</p>
                <Button
                    sx={buttonStyle}
                    onClick={activateAccount}>
                    Activer
                </Button>
            </div>
            <Dialog open={open}>
                <MessagePopup
                    closePopup={handleClose}
                    title="Félicitation !"
                    message=" Vous avez activé votre compte avec succès."
                    button={false}
                />
            </Dialog>
        </div>
    );
}

export default ActivateAccount;