import TopNavBar from "../../../components/navBar/topNavBar/TopNavBar";
import React, {useState} from "react";
import {Button, Dialog, IconButton, InputAdornment, TextField} from "@mui/material";
import SetPassword from "../../../services/api/fetch/SetPassword";

// Css
import './SetPasswordPage.css';
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import {Visibility, VisibilityOff} from "@mui/icons-material";

function SetPasswordPage() {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * useNavigate hook
     */
    const navigate = useNavigate();

    /**
     * useLocation hook
     *
     * @type {Location}
     */
    const location = useLocation();

    /**
     * URLSearchParams object
     *
     * @type {module:url.URLSearchParams}
     */
    const searchParams = new URLSearchParams(location.search);

    /**
     * Get email from URL
     *
     * @type {string}
     */
    const email = searchParams.get('email');

    /**
     * Get token from URL
     *
     * @type {string}
     */
    const token = searchParams.get('token');

    /**
     * Password
     */
    const [password, setPassword] = React.useState('');

    /**
     * Password confirmation
     */
    const [passwordConfirmation, setPasswordConfirmation] = React.useState('');

    /**
     * Show password
     */
    const [showPassword, setShowPassword] = React.useState(false);

    /**
     * Show password confirmation
     */
    const [showPasswordConfirmation, setShowPasswordConfirmation] = React.useState(false);

    /**
     * Error message
     */
    const [errorMessage, setErrorMessage] = React.useState('');

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Show password
     */
    const handleClickShowPassword = () => {setShowPassword(!showPassword);};

    /**
     * Show password confirmation
     */
    const handleClickShowPasswordConfirmation = () => {setShowPasswordConfirmation(!showPasswordConfirmation);};

    /**
     * Set the password
     */
    const handleSetPassword = () => {
        setErrorMessage("");
        if (password === '' || passwordConfirmation === '') {
            setErrorMessage("Veuillez remplir les champs");
        } else if(password !== passwordConfirmation) {
            setErrorMessage("Les mots de passe ne correspondent pas");
        } else {
            handleOpen();
            SetPassword(email, token,password);
        }
    }

    /**************************************************************************
     * Dialog
     *************************************************************************/

    /**
     * Boolean to open or close the dialog
     */
    const [open, setOpen] = useState(false);

    /**
     * Close the dialog
     */
    const handleClose = () => {
        setOpen(false);
    }

    /**
     * Open the dialog and start the timer (5s) to close it
     */
    const handleOpen = () => {
        setOpen(true);
        setTimeout(function () {
            handleClose();
            navigate('/login');
        }, 5000);
    }

    /**************************************************************************
     * Styles
     *************************************************************************/

    /**
     * TextField styles
     */
    const textFieldStyles = {
        width: '80%',
        marginBottom: '10px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)'
    };

    /**
     * Button styles
     */
    const buttonStyles = {
        width: '80%',
        backgroundColor: 'black',
        color: 'white',
        borderRadius: '20px',
        paddingTop: '10px',
        paddingBottom: '10px',
        ":hover": {
            backgroundColor: 'black',
            color: 'white',
        }
    };

    /**
     * Dialog styles
     */
    const dialogStyles = {
        '& .MuiPaper-root': {
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '15px',
            padding: '20px',
        }
    };

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div className="setPasswordPage">
            <TopNavBar numbar={3} />
            <div className="mainContentSetPassword">

                <TextField
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    id="password"
                    label="Nouveaux mot de passe"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={textFieldStyles}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    type={showPasswordConfirmation ? "text" : "password"}
                    fullWidth
                    id="passwordConfirmation"
                    label="Confirmation mot de passe"
                    variant="outlined"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    sx={textFieldStyles}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPasswordConfirmation}
                                >
                                    {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <div className="ErrorMessage">
                    {errorMessage}
                </div>
                <Button onClick={handleSetPassword} variant="contained" sx={buttonStyles}>
                    Enregistrer
                </Button>
                <Dialog
                    open={open}
                    sx={dialogStyles}
                >
                    <div>
                        Votre mot de passe a été modifié avec succès.
                    </div>
                </Dialog>
            </div>
        </div>
    );
}
export default SetPasswordPage;