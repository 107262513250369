import {gql} from "@apollo/client";

export const GET_CARD = gql`
    query {
        currentCard {
            firstName
            lastName
            middleName
            userType
            profilePicture
            logoURL1
            notes
            profession
            gender
            color
            address {
                addressType
                city
                country
                state
                street
                street2
                street3
                streetNumber
                zip
            }
            addresses {
                addressType
                city
                country
                state
                street
                street2
                street3
                streetNumber
                zip
            }
            email {
                address
                type
            }
            phone {
                number
                type
            }
            socialMedia {
                link
                platform
            }
            website {
                description
                url
            }
        }
    }
`;
export default GET_CARD;