import {Button} from "@mui/material";

const FormButtonSave = ({handleCreateCard, fieldsChanged, socialMediaChanged, profilePictureChanged}) => {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Variable to store the active button
     */
    const activeButton = fieldsChanged || profilePictureChanged || socialMediaChanged;

    /**************************************************************************
     * Styles
     *************************************************************************/

    /**
     * Get button style
     */
    const buttonStyle = {
        position: 'fixed',
        bottom: 70,
        width: '100%',
        zIndex: 999,
        color: '#ffffff',
        backgroundColor: activeButton ? '#426e86' : '#999999',
        '&:hover': {
            backgroundColor: activeButton ? '#6a94a8' : '#999999',
        },
        borderRadius: 0,
    }

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <Button
            onClick={handleCreateCard}
            disabled={!activeButton}
            sx={buttonStyle}
        >
            Enregistrer
        </Button>
    )
}
export default FormButtonSave;