const Logout = async () => {

    // Get the token from local storage
    const token = localStorage.getItem("token");

    /**
     * URL of the server
     *
     * @type {string}
     */
    const SERVER_URL = process.env.REACT_APP_LAETINE_LOGOUT_URL;

    const details = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        refresh_token: localStorage.getItem("refresh_token")
    };
    try {
        let requestBody = [];
        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            requestBody.push(encodedKey + "=" + encodedValue);
        }
        requestBody = requestBody.join("&");
        const response = await fetch(SERVER_URL, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: requestBody
        });
        if (!response.status === 204) {
            console.error('Erreur lors de la requête :', response);
        }
        return await response;
    } catch (error) {
        console.error('Erreur lors de la requête :', error);
    }
};
export default Logout;