import LoadingScreen from "../../components/loadingScreen/LoadingScreen";

function MentionLegal() {

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div>
            <LoadingScreen/>
        </div>
    );
}

export default MentionLegal;
