function validateForm(email, password, firstName, lastName) {
    const emailError = document.getElementById('emailError');
    const passwordError = document.getElementById('passwordError');
    const firstnameError = document.getElementById('firstnameError');
    const lastnameError = document.getElementById('lastnameError');

    let isValid = true;

    if (email === "") {
        emailError.innerHTML = "L'adresse email est requise";
        isValid = false;
    } else if (!isValidEmail(email)) {
        emailError.innerHTML = "Le format de l'adresse email n'est pas valide";
        isValid = false;
    } else {
        emailError.innerHTML = "";
    }
    if (password === "") {
        passwordError.innerHTML = "Le mot de passe est requis";
        isValid = false;
    } else if (password.length < 12) {
        passwordError.innerHTML = "Le mot de passe doit contenir au moins 12 caractères";
        isValid = false;
    } else if (password.length > 25) {
        passwordError.innerHTML = "Le mot de passe doit contenir au maximum 25 caractères";
        isValid = false;
    } else if (!containsSpecialCharacters(password)) {
        passwordError.innerHTML = "Le mot de passe doit contenir au moins un caractère spécial";
        isValid = false;
    } else if (!containsDigit(password)) {
        passwordError.innerHTML = "Le mot de passe doit contenir au moins un chiffre";
        isValid = false;
    } else {
        passwordError.innerHTML = "";
    }

    if (firstName === "") {
        firstnameError.innerHTML = "Le prénom est requis";
        isValid = false;
    } else if (firstName.length < 2) {
        firstnameError.innerHTML = "Le prénom doit contenir au moins 2 caractères";
        isValid = false;
    } else if (firstName.length > 32) {
        firstnameError.innerHTML = "Le prénom doit contenir au maximum 32 caractères";
        isValid = false;
    } else {
        firstnameError.innerHTML = "";
    }

    if (lastName === "") {
        lastnameError.innerHTML = "Le nom de famille est requis";
        isValid = false;
    } else if (lastName.length < 2) {
        lastnameError.innerHTML = "Le nom de famille doit contenir au moins 2 caractères";
        isValid = false;
    } else if (lastName.length > 32) {
        lastnameError.innerHTML = "Le nom de famille doit contenir au maximum 32 caractères";
        isValid = false;
    } else {
        lastnameError.innerHTML = "";
    }

    return isValid;
}

function isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
}

function containsSpecialCharacters(str) {
    // eslint-disable-next-line
    const specialCharPattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    return specialCharPattern.test(str);
}

function containsDigit(str) {
    const digitPattern = /\d/;
    return digitPattern.test(str);
}

export {validateForm};