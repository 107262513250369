import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import {useNavigate} from "react-router-dom";
import {useQuery} from "@apollo/client";
import ALLSHAREDCARDS from "../../../../services/api/queries/allShareCards";
import LoadingScreen from "../../../../components/loadingScreen/LoadingScreen";
import GetProfilePicture from "../../../../services/api/fetch/GetProfilePicture";


function ContactList() {

    /**************************************************************************
     * Api calls
     *************************************************************************/

    const { loading, data } = useQuery(ALLSHAREDCARDS);

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Search terms for the different fields
     */
    const [searchTermGlobal, setSearchTermGlobal] = useState('');

    /**
     * Search terms for First Name
     */
    const [searchTermFirstName] = useState('');

    /**
     * Search terms for Last Name
     */
    const [searchTermLastName] = useState('');

    /**
     * Search terms for Company
     */
    const [searchTermCompany] = useState('');

    /**
     * Contacts
     */
    const [contacts, setContacts] = useState([]);

    /**
     * Sort configuration
     */
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    /**
     * Sorted contacts based on the sortConfig
     */
    let sortedContacts = [...contacts];
    if (sortConfig.direction !== null) {
        sortedContacts.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
    }

    /**
     * Filtered contacts
     */
    const filteredContacts = sortedContacts.filter(contact =>
        (contact.firstName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchTermGlobal.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) ||
            contact.lastName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchTermGlobal.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) ||
            contact.company.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchTermGlobal.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())) &&
        contact.firstName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchTermFirstName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) &&
        contact.lastName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchTermLastName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) &&
        contact.company.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchTermCompany.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
    );

    /**
     * useNavigate hook
     */
    const navigate = useNavigate();

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Handle search global
     */
    const handleSearchGlobal = (event) => {setSearchTermGlobal(event.target.value);};

    /**
     * Handle sorting
     *
     * @param key
     */
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = null;
        }
        setSortConfig({ key, direction });
    };

    /**
     * Focus on a contact
     *
     * @param idContact
     */
    const focusOnContact = (idContact) => {
        navigate('/home/focusContact?id=' + idContact);
    }

    /**
     * Get profile picture url
     *
     * @param principal
     * @returns {Promise<null|string>}
     */
    const getProfilPicurl = async (principal) => {
        let response = await GetProfilePicture(principal);
        if (response) {
            return response.url;
        }
        return null;
    }

    /**************************************************************************
     * UseEffect
     *************************************************************************/

    // Fetch contacts
    useEffect(() => {
        if (data) {
            const fetchContacts = async () => {
                const contacts = data.allSharedCards.map(async contact => {
                    const profilePictureUrl = await getProfilPicurl(contact.principal);
                    return { ...contact, profilePictureUrl };
                });
                return Promise.all(contacts);
            }

            fetchContacts().then((contacts) => {
                setContacts(contacts);
            });
        }
    }, [data]);

    /**************************************************************************
     * Render
     *************************************************************************/

    // Loading
    if (loading) return <LoadingScreen />

    // Main render
    return (
        <div>
            <TextField
                label="Recherche contact"
                variant="outlined"
                fullWidth
                onChange={handleSearchGlobal}
            />
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Logo</TableCell>
                            <TableCell onClick={() => handleSort('firstName')}>
                                Prénom {sortConfig.key === 'firstName' ? (sortConfig.direction === 'ascending' ? '↓' : sortConfig.direction === 'descending' ? '↑' : '') : ''}
                            </TableCell>
                            <TableCell onClick={() => handleSort('lastName')}>
                                Nom {sortConfig.key === 'lastName' ? (sortConfig.direction === 'ascending' ? '↓' : sortConfig.direction === 'descending' ? '↑' : '') : ''}
                            </TableCell>
                            <TableCell onClick={() => handleSort('company')}>
                                Entreprise {sortConfig.key === 'company' ? (sortConfig.direction === 'ascending' ? '↓' : sortConfig.direction === 'descending' ? '↑' : '') : ''}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredContacts.map((contact) => (
                            <TableRow onClick={() => focusOnContact(contact.principal)}  key={contact.principal}>
                                <TableCell>
                                    <Avatar src={contact.profilePictureUrl} alt={`${contact.firstName} ${contact.lastName}`} />
                                </TableCell>
                                <TableCell style={{ maxWidth: '74px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{contact.firstName}</TableCell>
                                <TableCell style={{ maxWidth: '74px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{contact.lastName}</TableCell>
                                <TableCell style={{ maxWidth: '74px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{contact.company}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default ContactList;