import {CircularProgress} from "@mui/material";
import React from "react";
import img from "../../assets/logo/img.png";

// Css
import './LoadingScreen.css';

function LoadingScreen() {

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div className="loading-screen">
            <img src={img} alt="Logo laetine" className="loading-screen-logo"/>
            <CircularProgress
            />
        </div>
    );
}

export default LoadingScreen;