import React from 'react';
import {Button} from "@mui/material";

function LoginForm() {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Link connection
     */
    const linkConnection = process.env.REACT_APP_LINK_CONNECTION +
        "client_id=" + process.env.REACT_APP_CLIENT_ID +
        "&scope=" + process.env.REACT_APP_SCOPE +
        "&redirect_uri=" + process.env.REACT_APP_URI;

    /**************************************************************************
     * Styles
     *************************************************************************/

    /**
     * Get button style
     */
    const buttonStyle = {
        color: 'white',
        width: '100%',
        height: '50px',
        backgroundColor: '#426e86',
        '&:hover': {
            backgroundColor: '#61869a',
        },
    }

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div className="mainContentlogin" style={{textAlign: 'center'}}>
            <Button
                onClick={() => window.location.href = linkConnection}
                sx={buttonStyle}
                variant="outlined"
                type="submit"
            >
                Se connecter
            </Button>
        </div>
    );
}

export default LoginForm;