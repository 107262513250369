import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import hasRoles from "../security/hasRoles";


const ProtectedRoute = ({component: Component, roles, ...rest}) => {
    const location = useLocation();
    const isAuth = localStorage.getItem('token') !== null;

    if (!isAuth || !hasRoles(roles)) {
        return <Navigate to="/login" state={{from: location}}/>;
    }

    return <Component {...rest} />;
};

export default ProtectedRoute;