import React from 'react';
import {Dialog} from "@mui/material";
import MessagePopup from "../../../../../components/messagePopup/MessagePopup";

const MessageSaveDialog = ({open, handleClose, messagePopup}) => {

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <Dialog onClose={handleClose} open={open}>
            <MessagePopup
                closePopup={handleClose}
                title=""
                message={messagePopup}
                button={true}
                messageButton="Fermer"
            />
        </Dialog>
    );
};
export default MessageSaveDialog;