import {gql} from "@apollo/client";

export const ALLSHAREDCARDS = gql`
     query AllSharedCards {
        allSharedCards {
            company
            firstName
            id
            lastName
            principal
            profilePicture
        }
     }
`;
export default ALLSHAREDCARDS;