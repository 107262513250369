import React, {useState} from 'react';
import TopNavBar from "../../../components/navBar/topNavBar/TopNavBar";
import {Button, Dialog, TextField} from "@mui/material";
import ResetPassword from "../../../services/api/fetch/ResetPasword";

// Css
import './ResetPasswordPage.css';

function ResetPasswordPage() {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Email states for the form
     */
    const [email, setEmail] = useState('');

    /**
     * Email confirmation states for the form
     */
    const [emailConfirmation, setEmailConfirmation] = useState('');

    /**
     * Error message state
     */
    const [errorMessage, setErrorMessage] = useState('');

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Handle reset password
     */
    const handleResetPassword = () => {
        setErrorMessage("");
        if ((email || emailConfirmation) === '') {
            setErrorMessage("Veuillez remplir les champs");
        } else if (email !== emailConfirmation) {
            setErrorMessage("Les emails ne correspondent pas");
        } else {
            ResetPassword(email);
            haddleOpen();
        }
    }

    /**************************************************************************
     * Dialog
     *************************************************************************/

    /**
     * Dialog state
     */
    const [open, setOpen] = useState(false);

    /**
     * Dialog functions to close the dialog
     */
    const haddleClose = () => {setOpen(false);}

    /**
     * Dialog functions to open the dialog
     */
    const haddleOpen = () => {setOpen(true);}

    /**************************************************************************
     * Styles
     *************************************************************************/

    /**
     * TextField styles
     */
    const textFieldStyles = {
        width: '80%',
        marginBottom: '10px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)'
    };

    /**
     * Button styles
     */
    const buttonStyles = {
        width: '80%',
        backgroundColor: 'black',
        color: 'white',
        borderRadius: '20px',
        paddingTop: '10px',
        paddingBottom: '10px',
        ":hover": {
            backgroundColor: 'black',
            color: 'white',
        }
    };

    /**
     * Dialog styles
     */
    const dialogStyles = {
        '& .MuiPaper-root': {
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '15px',
            padding: '20px',
        }
    };

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div className="ResetPasswordPage">
            <TopNavBar numbar={3} />
            <div className="mainContentResetPassword">
                <p className="titleResetPassword">
                    Mot de passe oublié
                </p>
                <TextField
                    fullWidth
                    id="email"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={textFieldStyles}
                />
                <TextField
                    fullWidth
                    id="emailConfirmation"
                    label="Confirmation Email"
                    variant="outlined"
                    value={emailConfirmation}
                    onChange={(e) => setEmailConfirmation(e.target.value)}
                    sx={textFieldStyles}
                />
                <div className="ErrorMessage">
                    {errorMessage}
                </div>
                <Button variant="contained" onClick={handleResetPassword} sx={buttonStyles}>
                    Envoyer
                </Button>
                <Dialog open={open} onClose={haddleClose} sx={dialogStyles}>
                    <div>
                        Un email de réinitialisation de mot de passe vous a été envoyé.
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default ResetPasswordPage;
