import TopNavBar from "../../components/navBar/topNavBar/TopNavBar";
import BottomNavBar from "../../components/navBar/bottomNavBar/BottomNavBar";
import ListSettings from "./components/ListSettings";

function Settings() {

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div>
            <TopNavBar/>
            <ListSettings/>
            <BottomNavBar/>
        </div>
    );
}

export default Settings;