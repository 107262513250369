const GetProfilePicture = async (username) => {

    // Get the token from local storage
    const token = localStorage.getItem("token");

    /**
     * URL of the server
     *
     * @type {string}
     */
    const SERVER_URL = process.env.REACT_APP_LAETINE_API_SERVER_URL + '/api/v2/user/' +
        username + '/profilepicture/123';

    // Send the request to the server
    try {
        const response = await fetch(SERVER_URL, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });
        if (!response.status === 200) {
            console.error('Erreur lors de la requête :', response);
        }
        return await response;
    } catch (error) {
        console.error('Erreur lors de la requête :', error);
    }
};
export default GetProfilePicture;