import React, {useState} from "react";
import {Avatar, Box, Button, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import QRCode from 'qrcode.react';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import defaultAvatar from '../../assets/defaultavatar.png';
import AddAttachementDialog from "../../scenes/visitCardForm/components/dialog/addAttachementDialog/FileUploadDialog";
import MessagePopup from "../messagePopup/MessagePopup";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LanguageIcon from '@mui/icons-material/Language';

// Css
import './VisitCard.css';

function VisitCard(
    {
        cardData,
        attachement,
        qrcode,
        myCard,

    }
) {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * List of social media
     *
     */
    const listSocialMedia = [
        {label: "FACEBOOK", icon: <FacebookIcon/>},
        {label: "INSTAGRAM", icon: <InstagramIcon/>},
        {label: "TWITTER", icon: <TwitterIcon/>},
        {label: "LINKEDIN", icon: <LinkedInIcon/>},
        {label: "YOUTUBE", icon: <YouTubeIcon/>},
    ]

    /**************************************************************************
     * Dialog
     *************************************************************************/

    /**
     * Open dialog
     */
    const [open, setOpen] = useState(false);

    /**
     * Zoom QR code
     */
    const zoomQRcode = () => {
        setOpen(true);
    }

    /**
     * Close dialog
     */
    const handleClose = () => {
        setOpen(false);
    }

    /**
     * Boolean to open or close the dialog
     */
    const [openDialogAttachement, setOpenDialogAttachement] = useState(false);

    /**
     * Close the dialog
     */
    const handleCloseDialogAttachement = () => {
        setOpenDialogAttachement(false);
    };

    /**
     * Open the dialog and start the timer (5s) to close it
     */
    const handleOpenDialogAttachement = () => {
        setOpenDialogAttachement(true);
    }

    /**
     * Boolean to open or close the dialog for the error
     */
    const [openError, setOpenError] = useState(false);

    /**
     * Open the dialog for the error
     */
    const handleOpenError = () => {
        setOpenError(true);
    };

    /**
     * Close the dialog for the error
     */
    const handleCloseError = () => {
        setOpenError(false);
    };

    /**
     *
     */
    const [openDialogueAttachment, setOpenDialogueAttachment] = useState(false);

    /**
     *
     */
    const handleOpenDialogueAttachment = () => {
        setOpenDialogueAttachment(true);
    }

    /**
     *
     */
    const handleCloseDialogueAttachment = () => {
        setOpenDialogueAttachment(false);
    }

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Format phone number
     *
     * @param phoneNumber
     * @returns {string}
     */
    const formatPhoneNumber = (phoneNumber) => {
        let formattedNumber = "";
        for (let i = 0; i < phoneNumber.length; i++) {
            if (i % 2 === 0 && i !== 0) {
                formattedNumber += " ";
            }
            formattedNumber += phoneNumber[i];
        }
        return formattedNumber;
    };

    /**
     * Open google map with the address
     */
    const goToMap = () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${cardData.address?.streetNumber ?? ''}+${cardData.address?.street ?? ''}+${cardData.address?.zip ?? ''}+${cardData.address?.city ?? ''}+${cardData.address?.country ?? ''}`);
    }

    /**
     * Open the contact app with the phone number
     */
    const OpenAddConactPageOnPhone = () => {
        window.open(`tel:${cardData.phone}`);
    }

    /**
     * Open the mail app with the mail
     */
    const OpenAddMailPageOnPhone = () => {
        window.open(`mailto:${cardData.mail}`)
    }

    /**
     * Convert an integer color to a hex color
     *
     * @param intColor
     * @returns {string}
     */
    function intToHexColor(intColor) {
        if (intColor === undefined) {
            return '#ffb437';
        }

        let hexColor = intColor.toString(16);
        while (hexColor.length < 6) {
            hexColor = '0' + hexColor;
        }
        return '#' + hexColor;
    }

    /**
     *
     *
     * @param bgColor
     * @returns {string|string}
     */
    function getContrastColor(bgColor) {
        if (!bgColor) { return '#000'; } // Return black if no color provided

        // Convert hex color to RGB
        const rgb = parseInt(bgColor.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;

        // Calculate the brightness of the color
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        // Return white for dark colors and black for light colors
        return brightness > 155 ? '#000' : '#fff';
    }

    /**
     *
     *
     * @param bgColor
     * @returns {string|string}
     */
    function getContrastColorGrey(bgColor) {
        if (!bgColor) { return '#808080'; } // Return gray if no color provided

        // Convert hex color to RGB
        const rgb = parseInt(bgColor.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;

        // Calculate the brightness of the color
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        // Return dark gray for light colors and light gray for dark colors
        return brightness > 155 ? '#808080' : '#D3D3D3';
    }

    /**
     * the color of the text
     *
     * @type {string|string}
     */
    const textColor = getContrastColor(intToHexColor(cardData.color));

    /**
     * the color of the text in grey
     *
     * @type {string}
     */
    const textColorGrey = getContrastColorGrey(intToHexColor(cardData.color));


    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div>
            <div className="ZoneLogo">
                {cardData.logoUrl1 &&
                    <img style={{height: 190}} src={cardData.logoUrl1} alt="Logo" className="imgLogo"/>}
                {!cardData.logoUrl1 &&
                    <div style={{height: '100px', backgroundColor: `${intToHexColor(cardData.color)}`}}></div>}
            </div>
            <div className="topCard" style={{backgroundColor: `${intToHexColor(cardData.color)}`, color: textColor}}>
                <div className="ZoneAvatarName">
                    <Avatar
                        sx={{width: 80, height: 80, fontSize: 100, backgroundColor: '#ffffff'}}
                    >
                        {cardData.profilePictureUrl ?
                            <img
                                width={100}
                                height={100}
                                src={cardData.profilePictureUrl}
                                alt="Profile"
                                style={{objectFit: 'cover'}}
                            />
                            : <img
                                width={100}
                                height={100}
                                src={defaultAvatar}
                                alt="Default Avatar"
                                style={{objectFit: 'cover'}}
                            />
                        }
                    </Avatar>
                    <div className="nameSurname">
                        {(cardData.prenom || cardData.nom) &&
                            <div className="name">
                                {cardData.prenom} {cardData.nom}
                            </div>
                        }
                        <div style={
                            {
                                fontSize: 20,
                                color: textColorGrey
                            }
                        }>
                            {cardData.profession}
                        </div>
                        <div className="listSMA">
                            {cardData.socialMedia?.map((socialMedia, index) => {
                                const socialMediaItem = listSocialMedia.find(item => item.label === socialMedia.platform);
                                if (socialMediaItem) {
                                    return (
                                        <div key={index}>
                                            <a href={socialMedia.link}
                                               style={{color: 'inherit', textDecoration: 'none'}}>
                                                {React.cloneElement(socialMediaItem.icon, {style: {fontSize: '40px'}})}
                                            </a>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    </div>
                </div>
                <div className="QR-codeZone">
                    <div className="QR-codeButton">
                        <div className="iconTopCard" onClick={qrcode ? zoomQRcode : null}>
                            <QrCode2Icon
                                sx={{fontSize: 40, color: qrcode ? 'inherit' : 'grey'}}
                            />
                        </div>
                        {attachement && myCard &&
                            <div className="iconTopCard" onClick={handleOpenDialogAttachement}>
                                <AttachFileIcon
                                    onClick={handleOpenDialogAttachement}
                                    style={{color: 'inherit'}}
                                />
                            </div>
                        }
                        {attachement && !myCard &&
                            <div className="iconTopCard" onClick={handleOpenDialogueAttachment}>
                                <AttachFileIcon
                                    onClick={handleOpenDialogueAttachment}
                                    style={{color: 'inherit'}}
                                />
                            </div>
                        }
                        {cardData.websiteUrl &&
                            <a href={cardData.websiteUrl} style={{textDecoration: "none", color: 'inherit'}}>
                                <div className="iconTopCard">
                                    <LanguageIcon
                                        onClick={handleOpenDialogAttachement}
                                        style={{color: 'inherit'}}
                                    />
                                </div>
                            </a>
                        }
                    </div>
                    <div className={"cardBy"} style={{color: textColorGrey}}>
                        Card by Laetine
                    </div>
                </div>
                <Dialog open={open} onClose={handleClose} onClick={handleClose} fullWidth={true} maxWidth="md">
                    {qrcode &&
                        <div className="containerQrCode">
                            <QRCode className="qrCode" value={cardData.QRcodeUrl} style={{transform: 'scale(2)'}}/>
                        </div>
                    }
                </Dialog>
            </div>
            <div>
                <div className="card">
                    <div className="infoCardZone">
                        {cardData.notes}
                    </div>
                    {cardData.phone &&
                        <div className="infoCardZone" onClick={OpenAddConactPageOnPhone}>
                            {formatPhoneNumber(cardData.phone)}
                        </div>
                    }
                    {cardData.mail &&
                        <div className="infoCardZone" onClick={OpenAddMailPageOnPhone}>
                            {cardData.mail}
                        </div>
                    }

                    {cardData.address &&
                        <div className="infoCardZone" onClick={goToMap}>
                            {cardData.address?.streetNumber ?? ''}{" "}
                            {cardData.address?.street ?? ''}{" "}
                            <br/>
                            {cardData.address?.zip ?? ''}{" "}
                            {cardData.address?.city ?? ''}{" "}
                            <br/>
                            {cardData.address?.country ?? ''}
                        </div>
                    }
                </div>
            </div>

            <AddAttachementDialog
                open={openDialogAttachement}
                handleClose={handleCloseDialogAttachement}
                handleOpenError={handleOpenError}
            />
            <Dialog open={openError}>
                <MessagePopup
                    closePopup={handleCloseError}
                    title="Oops !"
                    message="Il s'emblait que votre fichier est trop lourd. Veuillez choisir un fichier de moins de 4 Mo."
                    messageButton="Fermer"
                    button={true}
                />
            </Dialog>
            <Dialog open={openDialogueAttachment} onClose={handleCloseDialogueAttachment}>
                <DialogTitle>Attachment</DialogTitle>
                <DialogContent dividers>
                    {cardData.listAttachment.map((attachment, index) => (
                        <Box
                            key={index}
                            mb={2}
                            p={2}
                            border={1}
                            borderRadius={4}
                            borderColor="grey.300"
                            boxShadow={2}
                        >
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                                <Typography variant="h6">{attachment.fileName}</Typography>

                            </Box>
                            <Typography variant="body2" color="textSecondary">
                                {attachment.description}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                href={attachment.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Download
                            </Button>
                        </Box>
                    ))}
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default VisitCard;

