import {gql} from "@apollo/client";

const CREATE_CARD_MUTATION = gql`
  mutation CreateCard(
        $firstName: String!
        $lastName: String!
        $phone: String!
        $city: String!
        $street: String!
        $zip: String!
        $streetNumber: String!
        $country: String!
        $email: String!
        $SocialMedia: [SocialMediaInput]
        $website: WebsiteInput
        $logoUrl1: String!
        $notes: String!
        $profession: String!
        $gender: Gender!
        $color: Int!
  ) {
    createCard(
        card: { 
            address: {
                city: $city
                street: $street
                zip: $zip
                streetNumber: $streetNumber
                country: $country
            }
            firstName: $firstName 
            lastName: $lastName
            phone: [{number: $phone, type: "mobile"}]
            email: [{type: "buisiness", address: $email}]
            socialMedia: $SocialMedia
            website: $website
            logoURL1: $logoUrl1
            notes: $notes
            profession: $profession
            gender: $gender
            color: $color
        }
    )
  }
`;
export default CREATE_CARD_MUTATION;