import {useEffect, useState} from 'react';
import {QrReader} from 'react-qr-reader';
import {Button, Dialog, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useLazyQuery} from "@apollo/client";
import CODECONSUMED from "../../../../services/api/queries/CodeConsumed";

// Css
import './QrcodeScanner.css';

const QrCodeScanner = () => {

    /**************************************************************************
     * Api calls
     *************************************************************************/

    /**
     * Consume code query
     */
    const [consumeCode] = useLazyQuery(CODECONSUMED);

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Data state for the QR code
     */
    const [data, setData] = useState('No result');

    /**
     * Camera availability state
     */
    const [cameraAvailable, setCameraAvailable] = useState(true);

    /**
     * Navigate function
     */
    const navigate = useNavigate();

    /**
     * Text state for the dialog
     */
    const [text] = useState('No result');

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Handle scan
     *
     * @param result
     */
    const handleScan = (result) => {
        if (result) {
            consumeCode({variables: {qrCode: result.text}});
            setData(result.text);
            handleOpen();
        } else {
/*
            console.log("Code already used");
*/
        }
    };

    /**
     * Navigate to the url
     */
    const navigateToUrl = () => {
        window.location.href = data;
    };

    /**
     * Handle error
     *
     * @param err
     */
    const handleError = (err) => {
        console.error(err);
    };

    /**
     * Redirect to the home page
     */
    const redirectHome = () => {
        navigate("/home");
    };

    /**************************************************************************
     * Dialog
     *************************************************************************/

    /**
     * Dialog state
     */
    const [open, setOpen] = useState(false);

    /**
     * Open the dialog
     */
    const handleOpen = () => {
        setOpen(true);
    };

    /**
     * Close the dialog
     */
    const handleClose = () => {
        setOpen(false);
    };

    /**************************************************************************
     * UseEffect
     *************************************************************************/

    // Check camera availability
    useEffect(() => {
        const checkCameraAvailability = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoInputDevices = devices.filter(device => device.kind === 'videoinput');
                setCameraAvailable(videoInputDevices.length > 0);
            } catch (error) {
                console.error("Error checking camera availability:", error);
                setCameraAvailable(false);
            }
        };

        checkCameraAvailability();
    }, []);

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div className="QRScannerContainer">
            {cameraAvailable ? (
                <div className="QRcode">
                    <QrReader
                        onResult={handleScan}
                        onError={handleError}
                        style={{ width: '100%' }}
                        constraints={{ facingMode: 'environment' }}
                    />
                    <Dialog open={open} onClose={handleClose}>
                        <div className="dialog-content">
                            <div>
                                Sauvegarder ? {text}
                            </div>
                            <div className="dialog-buttons">
                                <Button className="custom-button" onClick={navigateToUrl}>
                                    Oui
                                </Button>
                                <Button className="custom-button" onClick={redirectHome}>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                </div>
            ) : (
                <Typography variant="h6" color="error" style={{padding:15, textAlign: 'center'}}>
                    Aucune caméra disponible. Veuillez vérifier vos paramètres de caméra.
                </Typography>
            )}
        </div>
    );
};

export default QrCodeScanner;
