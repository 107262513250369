import React from 'react';
import './MyUnivers.css';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import BalanceIcon from '@mui/icons-material/Balance';
import ConstructionIcon from '@mui/icons-material/Construction';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import EventIcon from '@mui/icons-material/Event';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import HomeIcon from '@mui/icons-material/Home';

function MyUnivers() {

    // Main render
    return (
        <div className="my-univers">
            <div className="my-univers__mainBox">
                <div className="universVerticalContent">
                    <button className="universVerticalContent__button">
                        <LocalHospitalIcon/>
                    </button>

                    <button className="universVerticalContent__button">
                        <AirplanemodeActiveIcon/>
                    </button>

                    <button className="universVerticalContent__button">
                        <AudiotrackIcon/>
                    </button>
                </div>

                <div className="universVerticalContent">
                    <button className="universVerticalContent__button">
                        <BalanceIcon/>
                    </button>

                    <button className="universVerticalContent__button">
                        <ConstructionIcon/>
                    </button>

                    <button className="universVerticalContent__button">
                        <EmojiPeopleIcon/>
                    </button>

                    <button className="universVerticalContent__button">
                        <EmojiTransportationIcon/>
                    </button>
                </div>

                <div className="universVerticalContent">
                    <button className="universVerticalContent__button">
                        <EventIcon/>
                    </button>

                    <button className="universVerticalContent__button">
                        <FastfoodIcon/>
                    </button>

                    <button className="universVerticalContent__button">
                        <HomeIcon/>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MyUnivers;
