import {gql} from "@apollo/client";

export const DeleteAttachement = gql`
    
    mutation DeleteAttachment(
        $id: String!
    ) {
        deleteAttachment(id: $id)
    }
`;
export default DeleteAttachement;
