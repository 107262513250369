import React, {useState} from "react";
import TopNavBar from "../../../components/navBar/topNavBar/TopNavBar";
import BottomNavBar from "../../../components/navBar/bottomNavBar/BottomNavBar";
import VisitCard from "../../../components/visitCard/VisitCard";

// Css
import './MyCardPro.css';

function MyCardPro() {

    /**************************************************************************
     * Api calls
     *************************************************************************/

/*    /!**
     * Get the current card data
     *!/
    const {loading: loadingCurrentCard, data: dataCurrentCard} = useQuery(CURRENT_CARD_QUERY);

    /!**
     * Get the attachments
     *!/
    const {loading: loadingAttachment, data: dataAttachment} = useQuery(GetAttachementQuery);

    /!**
     * Share card mutation
     *!/
    const [shareCard] = useMutation(SHARE_CARD_MUTATION);*/

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Card data
     */
    const [cardData] = useState({
        nom: 'entreprise',
        prenom: '',
        phone: '',
        mail: '',
        address: null,
        profilePictureUrl: null,
        socialMedia: [],
        QRcodeUrl: '',
        listAttachment: [],
        websiteUrl: ''
    });

    /**************************************************************************
     * Render
     *************************************************************************/

    // Loading screen
/*
    if (loadingCurrentCard || loadingAttachment) return <LoadingScreen/>;
*/

    // Main render
    return (
        <div>
            <TopNavBar numbar={1}/>
            <VisitCard cardData={cardData}/>
            <BottomNavBar/>
        </div>
    );
}

export default MyCardPro;