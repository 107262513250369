import {useLocation} from "react-router";
import CONSUME_CODE from "../../services/api/queries/consumeCode_Query";
import CARD_BY_PRINCIPAL from "../../services/api/queries/GetCardByPrincipal";
import {useMutation, useQuery} from "@apollo/client";
import LoadingScreen from "../../components/loadingScreen/LoadingScreen";
import React, {useEffect, useState} from "react";
import Address from "../../models/Address";
import VisitCard from "../../components/visitCard/VisitCard";
import TopNavBar from "../../components/navBar/topNavBar/TopNavBar";
import BottomNavBar from "../../components/navBar/bottomNavBar/BottomNavBar";

function ScannedCard() {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Card data
     */
    const [cardData, setCardData] = useState({
        nom: '',
        prenom: '',
        phone: '',
        mail: '',
        address: null,
        profilePictureUrl: null,
        socialMedia: [],
        QRcodeUrl: '',
        listAttachment: [],
        website: '',
        logoUrl1: '',
        profession: '',
        gender: '',
        notes: '',
        color: 0
    });

    /**
     * useLocation hook
     *
     * @type {Location}
     */
    const location = useLocation();

    /**
     * URLSearchParams object
     *
     * @type {module:url.URLSearchParams}
     */
    const searchParams = new URLSearchParams(location.search);

    /**
     * Get code from URL
     *
     * @type {string}
     */
    const code = searchParams.get('code');

    /**************************************************************************
     * Api calls
     *************************************************************************/

    /**
     * Get the current card data
     */
    const [consumeCode, {loading: loadingConsumeCode, data: dataConsumeCode}] = useMutation(CONSUME_CODE);

    /**
     * email principal
     */
    const [principal, setPrincipal] = useState(null);

    /**
     * Get the card data by principal
     */
    const { loading: loadingCard, data: dataCard } = useQuery(CARD_BY_PRINCIPAL, {
        skip: !principal,
        variables: { principal },
    });


    /**************************************************************************
     * UseEffect
     *************************************************************************/

    // Fetch card data
    useEffect(() => {
        consumeCode({ variables: { code } });
    }, [code, consumeCode]);

    // Set principal
    useEffect(() => {
        if (dataConsumeCode && dataConsumeCode.consumeCode) {
            setPrincipal(dataConsumeCode.consumeCode);
        }
    }, [dataConsumeCode]);

    // Set card data
    useEffect(() => {
        if (dataCard && dataCard.cardByPrincipal) {
            const {firstName, lastName, phone, email, socialMedia, website, logoURL1, profession, gender, notes, color} = dataCard.cardByPrincipal;
            const address = dataCard.cardByPrincipal.address ? new Address(
                dataCard.cardByPrincipal.address.addressType || '',
                dataCard.cardByPrincipal.address.city || '',
                dataCard.cardByPrincipal.address.country || '',
                dataCard.cardByPrincipal.address.state || '',
                dataCard.cardByPrincipal.address.street || '',
                dataCard.cardByPrincipal.address.street2 || '',
                dataCard.cardByPrincipal.address.street3 || '',
                dataCard.cardByPrincipal.address.streetNumber || '',
                dataCard.cardByPrincipal.address.zip || ''
            ) : null;
            setCardData({
                nom: lastName || '',
                prenom: firstName || '',
                phone: phone ? phone[0].number : '',
                mail: email ? email[0].address : '',
                address,
                profilePictureUrl: null,
                socialMedia: socialMedia || [],
                listAttachment: [],
                QRcodeUrl: '',
                website: website ? website.url : '',
                logoUrl1: logoURL1 || '',
                profession: profession || '',
                gender: gender || '',
                notes: notes || '',
                color: color || 0
            });
        }
    }, [dataCard]);

    /**************************************************************************
     * Render
     *************************************************************************/

    // Loading
    if (loadingConsumeCode || loadingCard) return <LoadingScreen/>;

    // Main render
    return (
        <div>
            <TopNavBar numbar={1}/>
            <VisitCard cardData={cardData}/>
            <BottomNavBar/>
        </div>
    );
}

export default ScannedCard;