import {useLocation} from "react-router";
import TopNavBar from "../../../../components/navBar/topNavBar/TopNavBar";
import {useQuery} from "@apollo/client";
import CARDBYPRINCIPAL from "../../../../services/api/queries/CardByPrincipal";
import LoadingScreen from "../../../../components/loadingScreen/LoadingScreen";
import React, {useEffect, useState} from "react";
import VisitCard from "../../../../components/visitCard/VisitCard";
import GetProfilePicture from "../../../../services/api/fetch/GetProfilePicture";
import GetAttachementQuery from "../../../../services/api/queries/GetAttachementQuery";

function FocusContact() {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * useLocation hook
     *
     * @type {Location}
     */
    const location = useLocation();

    /**
     * URLSearchParams object
     */
    const searchParams = new URLSearchParams(location.search);

    /**
     * Get id from URL
     */
    const code = searchParams.get('id');

    /**************************************************************************
     * Api calls
     *************************************************************************/

    /**
     * Get the current card data
     */
    const {loading, data} = useQuery(CARDBYPRINCIPAL, {
        variables: {principal: code},
    });

    const {loading: loadingAttachment, data: dataAttachment} = useQuery(GetAttachementQuery, {
        variables: { principle: code },
    });

    /**
     * Card data
     */
    const [cardData, setCardData] = useState({
        nom: '',
        prenom: '',
        phone: '',
        mail: '',
        address: null,
        profilePictureUrl: null,
        socialMedia: [],
        QRcodeUrl: '',
        listAttachment: []
    });

    /**************************************************************************
     * Functions
     *************************************************************************/


    /**************************************************************************
     * UseEffect
     *************************************************************************/

    // Fetch contacts
    useEffect( () => {
        const fetchData = async () => {
            let profilePictureUrl = null;
            let listAttachment = [];

            if (data) {
                const card = data.cardByPrincipal;

                let response = await GetProfilePicture(code);
                if (response) {
                    profilePictureUrl = response.url;
                }

                if (dataAttachment && dataAttachment.attachments) {
                    listAttachment = dataAttachment.attachments;
                }

                setCardData({
                    nom: card.lastName,
                    prenom: card.firstName,
                    phone: card.phone[0]?.number,
                    mail: card.email[0]?.address,
                    address: card.address,
                    profilePictureUrl,
                    socialMedia: card.socialMedia,
                    QRcodeUrl: '',
                    listAttachment: listAttachment,
                    websiteUrl: card.website ? card.website.url : '',
                    logoUrl1: card.logoURL1,
                    profession: card.profession || '',
                    gender: card.gender || '',
                    notes: card.notes || '',
                    color: card.color || 0
                });
            }
        }
        fetchData();
    }, [data, code]);

    /**************************************************************************
     * Styles
     *************************************************************************/


    /**************************************************************************
     * Render
     *************************************************************************/

    // Loading
    if (loading) return <LoadingScreen/>;

    // Main render
    return (
        <div>
            <TopNavBar numbar={1}/>
            <VisitCard cardData={cardData} attachement={true} qrcode={true}/>
        </div>
    );
}

export default FocusContact;