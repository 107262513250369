import React from 'react';
import logo2 from '../../../assets/logo/img_2.png';
import HomeIcon from "@mui/icons-material/Home";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {useLocation, useNavigate} from "react-router-dom";

// Css
import './TopNavBar.css';


function TopNavBar({numbar}) {

    /**
     * Navigate to another page
     */
    const navigate = useNavigate();

    /**
     * Get current location
     */
    const location = useLocation();

    const goBack = () => {
        navigate(-1);
    };

    /**
     * Redirect to home page
     */
    const redirectHome = () => {
        if (location.pathname !== "/home") {
            navigate("/home");
        }
    };

    /**
     * Redirect to home page
     */
    const redirectlogin = () => {
        if (location.pathname !== "/login") {
            navigate("/login");
        }
    };

    /**************************************************************************
     * Render
     *************************************************************************/

    if (numbar === 1) {
        return (
            <div>
                <div className="TopNavBarCard">
                    <KeyboardBackspaceIcon
                        onClick={goBack}
                        sx={{
                            position: 'absolute',
                            color: '#ffffff',
                            left: '20px',
                            background: 'rgba(0, 0, 0, 0.5)',
                            borderRadius: '30%',
                            padding: '5px',
                        }}
                    />
                    <HomeIcon
                        onClick={redirectHome}
                        sx={{
                            position: 'absolute',
                            color: '#ffffff',
                            right: '20px',
                            background: 'rgba(0, 0, 0, 0.5)',
                            borderRadius: '30%',
                            padding: '5px',
                        }}/>
                </div>
            </div>
        )
    }
    if (numbar === 2) {
        return (
            <div>
                <div className="TopNavBar">
                    <div className="TopNavBarTitle">
                        <img className="logoTopNavBar" src={logo2} alt="Logo"/>
                    </div>
                </div>
            </div>
        )
    }
    if (numbar === 3) {
        return (
            <div>
                <div className="TopNavBar">
                    <div className="TopNavBarTitle">
                        <img className="logoTopNavBar" src={logo2} alt="Logo"/>
                    </div>
                    <KeyboardBackspaceIcon
                        onClick={redirectlogin}
                        sx={{
                            position: 'absolute',
                            color: '#000000',
                            left: '20px',
                        }}/>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div style={{
                height: '4%'
            }}>
            </div>
            <div className="TopNavBar">
                <div className="TopNavBarTitle">
                    <img className="logoTopNavBar" src={logo2} alt="Logo"/>
                </div>
                <HomeIcon
                    onClick={redirectHome}
                    sx={{
                        position: 'absolute',
                        color: '#000000',
                        right: '20px',
                    }}/>
            </div>
        </div>
    )
}

export default TopNavBar;
