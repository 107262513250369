import React, {useEffect, useRef} from 'react';
import {useLocation} from "react-router";
import GetTokenFetch from "../../services/api/fetch/GetTokenFetch";
import {useNavigate} from "react-router-dom";
import LoadingScreen from "../../components/loadingScreen/LoadingScreen";

function ConnectionRedirection() {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Function to navigate to a different page
     */
    const navigate = useNavigate();

    /**
     * useLocation hook
     *
     * @type {Location}
     */
    const location = useLocation();

    /**
     * Initialized ref
     */
    const initialized = useRef(false);

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Function to redirect to the signup page
     */
    const redirectHome = () => {
        navigate("/home");
    };

    /**************************************************************************
     * Api calls
     *************************************************************************/

    /**
     * URLSearchParams object
     *
     * @type {module:url.URLSearchParams}
     */
    const fetchData = async () => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');
        if (code != null) {
            return await GetTokenFetch(code);
        }
    };

    /**************************************************************************
     * UseEffect
     *************************************************************************/

    // UseEffect to fetch the data
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            fetchData().then(response =>
                response.json().then(value => {
                    localStorage.setItem("token", value.access_token);
                    localStorage.setItem("refresh_token", value.refresh_token);
                    localStorage.setItem("expires_in", value.expires_in);
                    localStorage.setItem("refresh_expires_in", value.refresh_expires_in);
                })
            );

            setTimeout(() => {
                redirectHome();
            }, 2000);
        }
    }, [fetchData, redirectHome]);

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div>
            <LoadingScreen/>
        </div>
    );
}

export default ConnectionRedirection;