import SignUpForm from "./signUpForm/SignUpForm";
import React from 'react';
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import img_1 from "../../../assets/logo/img_2.png";

// Css
import './SignUp.css';

function SignUp() {

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Function to navigate to a different page
     */
    const navigate = useNavigate();

    /**
     * Function to redirect to the signup page
     */
    const redirectLogin = () => {
        navigate("/login");
    }

    /**
     * Function to redirect to the mention legal page
     */
    const redirectMentionLegal = () => {
        navigate("/MentionLegal");
    }

    /**************************************************************************
     * Styles
     *************************************************************************/

    /**
     * Get button style
     */
    const buttonStyle = {
        color: 'white',
        width: '100%',
        height: '50px',
        backgroundColor: '#FFA500',
        '&:hover': {
            backgroundColor: '#ffb437',
        },
    }

    /**
     * Get button style
     */
    const buttonStyle2 = {
        width: '100%',
        marginTop: '10%',
        textAlign: 'center',
    }

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div className="mainContentSignup">
            <img className="logoSignUpPage" src={img_1} alt="Description de l'imag"/>
            <div className="signupText">
                <div className="titleformsignup">Inscription</div>
                <SignUpForm/>
                <div className="footerForm">
                    <Button onClick={redirectLogin} sx={buttonStyle}>
                        Déja inscris ?
                    </Button>
                    <Button onClick={redirectMentionLegal} variant="outlined" sx={buttonStyle2}>
                        Mention légal
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default SignUp;