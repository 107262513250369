import {gql} from "@apollo/client";

const LIST_ATTACHMENTS = gql`
        query Attachments {
            attachments {
                description
                fileName
                fileType
                id
                size
                timestamp
                uploadType
                temporary
            }
        }
    `;
export default LIST_ATTACHMENTS;