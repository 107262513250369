import {gql} from "@apollo/client";

const SHARE_CARD_MUTATION = gql`
  mutation ShareCard (
    $permanentAttachments: [String]!
    $temporaryAttachments: [String]!
  ) {
    shareCard(
        input: { 
            permanentAttachments: $permanentAttachments, 
            temporaryAttachments: $temporaryAttachments 
        }
    )
  }
`;
export default SHARE_CARD_MUTATION;