import React, { useState } from 'react';
import ContactList from "./components/contactList/ContactList";
import TopNavBar from "../../components/navBar/topNavBar/TopNavBar";
import BottomNavBar from "../../components/navBar/bottomNavBar/BottomNavBar";
import MyUnivers from "./components/myUnivers/MyUnivers";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

// Css
import "./Contact.css";

function Contact() {

    /**************************************************************************
    * Variables
    *************************************************************************/

    /**
     * Show or hide MyUnivers
     */
    const [showUnivers, setShowUnivers] = useState(false);

    /**************************************************************************
    * Functions
    ***********************************************************************/

    /**
     * Toggle MyUnivers
     */
    const handleToggleUnivers = () => {
        setShowUnivers(!showUnivers);
    };

    /**************************************************************************
    * Render
    ***********************************************************************/

    // Main render
    return (
        <div>
            <TopNavBar/>
            <div className="toggle-bar" onClick={handleToggleUnivers}>
                {showUnivers ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </div>
            {showUnivers && <MyUnivers />}
            <ContactList/>
            <BottomNavBar/>
        </div>
    );
}

export default Contact;