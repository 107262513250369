import React from 'react';
import { Avatar } from "@mui/material";

// Css
import "./ActualiteBox.css";

function ActualiteBox({ urlImageAvatar, name, surname, text, date }) {
    return (
        <div className="actualite-box">
            <div className="actualite-box__content">
                <div className="actualite-box__line">
                    <Avatar src={urlImageAvatar} sx={{ width: 55, height: 55 }} />
                    <div className="actualite-box__name-date">
                        <div className="actualite-box__name">{name} {surname}</div>
                        <div className="actualite-box__date">{date}</div>
                    </div>
                </div>
                <div className="actualite-box__actualite">
                    <p className="actualite-box__text">{text}</p>
                </div>
            </div>
        </div>
    );
}

export default ActualiteBox;
