import {gql} from '@apollo/client';

const CARD_BY_PRINCIPAL = gql`
  query CardByPrincipal ($principal: String!) {
    cardByPrincipal(principal: $principal) {
        company
        companyId
        firstName
        lastName
        middleName
        profilePicture
        userType
        website {
            description
            url
        }
        socialMedia {
            link
            platform
        }
        phone {
            number
            type
        }
        logo {
            description
            height
            type
            url
            width
        }
        email {
            address
            type
        }
        addresses {
            addressType
            city
            country
            state
            street
            street2
            street3
            streetNumber
            zip
        }
        address {
            addressType
            city
            country
            state
            street
            street2
            street3
            streetNumber
            zip
        }
    }
  }
`;

export default CARD_BY_PRINCIPAL;