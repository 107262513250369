import React, {useEffect} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import NotFound from './scenes/notFound/NotFound';
import {getRoutes} from './routes';
import ProtectedRoute from './services/route/ProtectedRoute';
import Login from './scenes/login/components/Login'; // Import your Login component

function App() {
    const routes = getRoutes();
    const isAuth = localStorage.getItem('token') !== null;

    useEffect(() => {
        if (isAuth && window.location.pathname === '/') {
            window.location.href = '/home';
        }
    } , []);

    return (
        <Router>
            <Routes>
                {routes.map((route, index) => {
                    if (route.path.startsWith('/home')) {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={<ProtectedRoute component={route.component} roles={route.roles}/>}
                            />
                        );
                    } else {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={<route.component/>}
                            />
                        );
                    }
                })}
                <Route path="/login" element={<Login/>}/>
                <Route path="/" element={isAuth ? <Navigate to="/home" /> : <Navigate to="/login" />}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Router>
    );
}

export default App;