/**
 * Send a request to the server to activate the user account
 *
 * @param email
 * @param token
 * @returns {Promise<Response>}
 */
const registerRequest = async (email, token) => {

    /**
     * URL of the server
     *
     * @type {string}
     */
    const SERVER_URL = process.env.REACT_APP_LAETINE_API_SERVER_URL + '/api/v2/user/' + email + '/activate';

    /**
     * Request body of the request
     */
    const requestBody = {token: token};

    // Send the request to the server
    try {
        const response = await fetch(SERVER_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });
        return await response;
    } catch (error) {
        console.error('Erreur lors de la requête :', error);
    }
};
export default registerRequest;
