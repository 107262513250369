import Login from "./scenes/login/components/Login";
import HomePage from "./scenes/homePage/HomePage";
import ActivateAccount from "./scenes/activateAccount/ActivateAccount";
import SignUp from "./scenes/signUp/components/SignUp";
import ConnectionRedirection from "./scenes/connectionRedirect/ConnectionRedirect";
import MyCard from "./scenes/myCard/myCardPerso/MyCard";
import VisitCardForm from "./scenes/visitCardForm/VisitCardForm";
import Scanner from "./scenes/qrcodeScanner/components/Scanner";
import MentionLegal from "./scenes/mentionLegal/MentionLegal";
import ScannedCard from "./scenes/ScannedCard/ScannedCard";
import Contact from "./scenes/Contact/Contact";
import MyCardPro from "./scenes/myCard/myCardPro/MyCardPro";
import FocusContact from "./scenes/Contact/components/focusContact/FocusContact";
import Settings from "./scenes/setting/Settings";
import ResetPasswordPage from "./scenes/resetPassword/resetPasswordPage/ResetPasswordPage";
import SetPasswordPage from "./scenes/resetPassword/setPasswordPage/SetPasswordPage";

/**
 * Routes
 */
const routes = [
    {
        'name': 'root',
        'path': '/',
        'component': Login,
    },
    {
        'name': 'login',
        'path': '/login',
        'component': Login,
    },
    {
        'name': 'signup',
        'path': '/signup',
        'component': SignUp,
    },
    {
        'name': 'activationAccount',
        'path': '/action/activate-account',
        'component': ActivateAccount,
    },
    {
        'name': 'homePage',
        'path': '/home',
        'component': HomePage,
        //'roles': 'LT_USER',
    },
    {
        'name': 'redirectConnection',
        'path': '/reco',
        'component': ConnectionRedirection,
    },
    {
        'name': 'myCard',
        'path': '/home/myCard',
        'component': MyCard,
    },
    {
        'name': 'myCardCreate',
        'path': '/home/myCardCreate',
        'component': VisitCardForm,
    },
    {
        'name': 'qrcodeScanner',
        'path': '/home/qrcodeScanner',
        'component': Scanner,
    },
    {
        'name': 'MentionLegal',
        'path': '/MentionLegal',
        'component': MentionLegal,
    },
    {
        'name': 'ScannedCard',
        'path': '/ScannedCard',
        'component': ScannedCard,
    },
    {
    'name': 'contact',
    'path': '/home/contact',
    'component': Contact,
    },
    {
        'name': 'myCardPro',
        'path': '/home/myCardPro',
        'component': MyCardPro,
    },
    {
        'name': 'focusContact',
        'path': '/home/focusContact',
        'component': FocusContact,
    },
    {
        'name': 'setting',
        'path': '/home/setting',
        'component': Settings,
    },
    {
        'name': 'reset password page',
        'path': '/reset-password',
        'component': ResetPasswordPage,
    },
    {
        'name': 'set password page',
        'path': '/action/reset-account',
        'component': SetPasswordPage,
    },
];

/**
 * Compile routes
 *
 * @param parentRoute
 * @param subRoutes
 * @returns {*}
 */
const compile = (parentRoute, subRoutes) => {
    return subRoutes.flatMap(subRoute => {
        const newRoute = {
            'name': subRoute.name,
            'path': parentRoute.path + subRoute.path,
            'component': subRoute.component,
            'roles': (parentRoute.roles || []).concat((subRoute.roles || [])),
        };
        return (subRoute.routes) ? [...compile(newRoute, subRoute.routes)] : newRoute;
    });
}

/**
 * Get routes
 *
 * @returns {*}
 */
const getRoutes = () => {
    const parentRoute = {
        'name': '',
        'path': '',
    };
    return compile(parentRoute, routes);
}

export {getRoutes};
