import {gql} from "@apollo/client";

export const CARDBYPRINCIPAL = gql`
    query CardByPrincipal ($principal: String!){
        cardByPrincipal(principal: $principal) {
            company
            companyId
            firstName
            lastName
            middleName
            profilePicture
            userType
            color
            address {
                addressType
                city
                country
                state
                street
                street2
                street3
                streetNumber
                zip
            }
            addresses {
                addressType
                city
                country
                state
                street
                street2
                street3
                streetNumber
                zip
            }
            email {
                address
                type
            }
            logo {
                description
                height
                type
                url
                width
            }
            phone {
                number
                type
            }
            website {
                description
                url
            }
            socialMedia {
                link
                platform
            }
            gender
            logoURL1
            notes
            profession
        }
    }
`;
export default CARDBYPRINCIPAL;