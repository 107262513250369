const SendFile = async (file, description = 'attachment', type = 'COMMON', temporary) => {

    /**
     * URL of the server
     *
     * @type {string}
     */
    const SERVER_URL = process.env.REACT_APP_LAETINE_API_SERVER_URL + '/api/v2/card/attachment';

    // Get the token from local storage
    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.set('description', description);
    formData.set('type', type);
    formData.set('temporary', temporary);
    formData.append('files', file);

    // Send the request to the server
    try {
        const response = await fetch(SERVER_URL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        }).then(function (response) {
            return response.json();
        });
        if (!response.status === 202) {
            console.error('Erreur lors de la requête :', response);
            return await response.json();
        }
        return await response;
    } catch (error) {
        console.error('Erreur lors de la requête :', error);
    }
};
export default SendFile;