const GetTokenFetch = async (code) => {
    const SERVER_URL = process.env.REACT_APP_LAETINE_API_TOKEN_URL;

    const grant_type = "authorization_code";

    const details = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        code: code,
        grant_type: grant_type,
        redirect_uri: process.env.REACT_APP_URI
    };
    try {
        let requestBody = [];
        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            requestBody.push(encodedKey + "=" + encodedValue);
        }
        requestBody = requestBody.join("&");

        const response = await fetch(SERVER_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: requestBody
        });
        if (!response.status === 200) {
            console.error('Erreur lors de la requête :', response);
            const responseData = await response.json();
            throw new Error(responseData.title);
        }
        return response;
    } catch (error) {
        console.error('Erreur lors de la requête :', error);
        throw error;
    }
};
export default GetTokenFetch