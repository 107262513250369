export default class SocialMedia {
    constructor(platform, url) {
        this.link = url;
        this.platform = platform;
    }

    getPlatform() {
        return this.platform;
    }

    setPlatform(platform) {
        this.platform = platform;
    }

    getLink() {
        return this.link;
    }

    setLink(link) {
        this.link = link;
    }
}
