import React, { useEffect } from 'react';
import WHO_AM_I_QUERY from "../../services/api/queries/WHO_AM_I_QUERY";
import { useQuery } from "@apollo/client";
import BottomNavBar from "../../components/navBar/bottomNavBar/BottomNavBar";
import TopNavBar from "../../components/navBar/topNavBar/TopNavBar";
import LoadingScreen from "../../components/loadingScreen/LoadingScreen";
import ActualiteBox from "./components/ActualitéBox/ActualiteBox";

// Css
import './HomePage.css';
import Divider from "@mui/material/Divider";

function HomePage() {

    /**************************************************************************
     * Api calls
     *************************************************************************/

    /**
     * WhoAmI query to get the roles of the user
     */
    const { loading, data } = useQuery(WHO_AM_I_QUERY);

    /*
     * fake actualités
     */
    const actualites = [
        { name: "Jean", surname: "Charle", text: "Nouvelle boulangerie ouverte au centre-ville offrant une variété de pains artisanaux.", date: "01/04/24" },
        { name: "Marie", surname: "Dupont", text: "Le café local introduit une nouvelle gamme de thés biologiques.", date: "02/04/24" },
        { name: "Paul", surname: "Martin", text: "Le marché des agriculteurs sera désormais ouvert tous les week-ends à partir de ce samedi.", date: "03/04/24" },
        { name: "Lucie", surname: "Lemoine", text: "La librairie communautaire organise une rencontre avec des auteurs locaux.", date: "04/04/24" },
        { name: "David", surname: "Durand", text: "Nouveau studio de yoga offrant des cours gratuits pendant le premier mois.", date: "05/04/24" },
        { name: "Emma", surname: "Moreau", text: "Parc rénové rouvert avec de nouveaux équipements de jeu.", date: "06/04/24" },
        { name: "Lucas", surname: "Laurent", text: "Le restaurant local introduit un nouveau menu saisonnier avec des ingrédients frais.", date: "07/04/24" },
        { name: "Alice", surname: "Roux", text: "Galerie d'art ouvrant une nouvelle exposition mettant en vedette des artistes locaux.", date: "08/04/24" },
        { name: "Nathan", surname: "Simon", text: "Animalerie offrant des services de toilettage gratuits pour les animaux adoptés.", date: "09/04/24" },
        { name: "Sophie", surname: "Lefevre", text: "Salle de sport locale offrant une évaluation de fitness gratuite avec l'abonnement.", date: "10/04/24" },
        { name: "Mia", surname: "Bernard", text: "Centre communautaire organisant une soirée cinéma gratuite en famille chaque vendredi.", date: "11/04/24" },
        { name: "Léo", surname: "Dubois", text: "Nouveau magasin de vélos offrant des réductions sur tout l'équipement pour l'ouverture.", date: "12/04/24" },
        { name: "Juliette", surname: "Richard", text: "Fleuriste local organisant un atelier sur la création de votre propre bouquet.", date: "13/04/24" },
        { name: "Hugo", surname: "Robert", text: "La bibliothèque municipale annonce des heures d'ouverture prolongées le week-end.", date: "14/04/24" },
        { name: "Chloé", surname: "Petit", text: "Le magasin de glaces local introduit de nouvelles saveurs estivales.", date: "15/04/24" },
        { name: "Gabriel", surname: "Carre", text: "Le jardin communautaire organise une journée de plantation pour les bénévoles.", date: "16/04/24" },
        { name: "Lina", surname: "Perrin", text: "La boulangerie locale offre un café gratuit avec tout achat de pâtisserie.", date: "17/04/24" },
        { name: "Adam", surname: "Renaud", text: "Nouveau studio de danse offrant des cours d'initiation gratuits.", date: "18/04/24" },
        { name: "Manon", surname: "Blanc", text: "Le marché local étend sa sélection de produits biologiques.", date: "19/04/24" },
        { name: "Louis", surname: "Giraud", text: "Le centre communautaire organise une course de charité pour soutenir les refuges locaux.", date: "20/04/24" },
    ];


    /**************************************************************************
     * UseEffect
     *************************************************************************/

    /**
     * UseEffect to get the roles of the user
     */
    useEffect(() => {
        if (data) {
            const rolesString = data.whoAmI;

            if (rolesString) {
                const rolesArray = rolesString.split(': ');
                localStorage.setItem('username', rolesArray[0].split(' ')[0]);
                const roles = rolesArray[1].split(', ');
                localStorage.setItem('roles', JSON.stringify(roles));
            }
        }
    }, [data]);

    /**************************************************************************
     * Render
     *************************************************************************/

    // Loading screen
    if (loading) return <LoadingScreen />;

    // Main render
    return (
        <div>
            <TopNavBar numbar={2} />
            <h2 className="actualiteTitle">Fil d'actualité</h2>

            {actualites.map((actualite, index) => (
                <div key={index}>
                    <ActualiteBox
                        key={index}
                        urlImageAvatar="https://thispersondoesnotexist.com/"
                        name={actualite.name}
                        surname={actualite.surname}
                        text={actualite.text}
                        date={actualite.date}
                    />
                    <Divider />
                </div>
            ))}
            <BottomNavBar />
        </div>
    );
}

export default HomePage;
