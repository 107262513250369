import {gql} from "@apollo/client";

export const CONSUME_CODE = gql`
     mutation ConsumeCode(
        $code: String!
     ) {
        consumeCode(code: $code)
       }
       
       
`;
export default CONSUME_CODE;