import React, {useState} from 'react';
import {Button, Dialog, IconButton, InputAdornment, TextField} from "@mui/material";
import registerRequest from "../../../../services/api/fetch/SignUpFetch"
import {validateForm} from "../../../../services/security/VerifFormSignUp";
import MessagePopup from "../../../../components/messagePopup/MessagePopup";

// Css
import './SignUpForm.css';
import {Visibility, VisibilityOff} from "@mui/icons-material";

function SignUpForm() {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * email in the form
     */
    const [email, setEmail] = useState('');

    /**
     * password in the form
     */
    const [password, setPassword] = useState('');

    /**
     * firstName in the form
     */
    const [firstName, setFirstname] = useState('');

    /**
     * lastName in the form
     */
    const [lastName, setLastname] = useState('');

    /**************************************************************************
     * Dialog
     *************************************************************************/

    /**
     * State to manage the opening and closing of the dialog
     */
    const [openError, setOpenError] = useState(false);

    /**
     * Function to close the SignUp dialog
     */
    const handleCloseError = () => {
        setOpenError(false);
    }

    /**
     * Function to open the SignUp dialog
     */
    const handleOpenError = () => {
        setOpenError(true);
    }

    /**
     * State to manage the opening and closing of the dialog
     */
    const [openValidate, setOpenValidate] = useState(false);

    /**
     * Function to close the SignUp dialog
     */
    const handleCloseValidate = () => {
        setOpenValidate(false);
    }

    /**
     * Function to open the SignUp dialog
     */
    const handleOpenValidate = () => {
        setOpenValidate(true);
    }

    const [showPassword, setShowPassword] = useState(false);


    /**************************************************************************
     * Functions
     *************************************************************************/

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
     * Function to submit the form
     *
     * @param event
     * @returns {Promise<void>}
     */
    const submit = async (event) => {
        event.preventDefault();
        if (!validateForm(email, password, firstName, lastName)) {
            return;
        }
        let response = await registerRequest(email, password, firstName, lastName);
        if (response.status === 201) {
            handleOpenValidate();
        } else {
            handleOpenError();
            /*
                      let promise = response.json();
                        promise.then(function(value) {
                            console.log(value.violations
                                .map(violation => violation.message)
                                .join(", ")
                            );
                        });
            */
        }
    }

    /**************************************************************************
     * Styles
     *************************************************************************/

    /**
     * Get button style
     */
    const buttonStyle = {
        color: 'white',
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
        marginBottom: '10px',
        backgroundColor: '#444444',
        '&:hover': {
            backgroundColor: '#626161',
        },
    }

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div className="mainContent">
            <form className="signUpForm" onSubmit={submit}>
                <TextField fullWidth className="inputFormText" value={email} label="Email"
                           onChange={(e) => setEmail(e.target.value)}
                />
                <div id="emailError" className="errorForm"></div>

                <TextField
                    fullWidth
                    className="inputFormText"
                    value={password}
                    label="Mot de passe"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <div id="passwordError" className="errorForm"></div>


                <TextField fullWidth className="inputFormText" value={firstName} label="Prénom"
                           onChange={(e) => setFirstname(e.target.value)}
                />
                <div id="firstnameError" className="errorForm"></div>

                <TextField fullWidth className="inputFormText" value={lastName} label="Nom"
                           onChange={(e) => setLastname(e.target.value)}
                />
                <div id="lastnameError" className="errorForm"></div>

                <Button sx={buttonStyle} variant="outlined" type="submit">
                    S'inscrire
                </Button>
            </form>

            <Dialog open={openError} onClose={() => handleCloseError()}>
                <MessagePopup
                    closePopup={handleCloseError}
                    title="Oops !"
                    message="Erreur lors de la création du compte"
                    button={true}
                    messageButton="Fermer"
                />
            </Dialog>
            <Dialog open={openValidate}>
                <MessagePopup
                    closePopup={handleCloseValidate}
                    title="Compte crée avec succès !"
                    message="Un email de validation vous a été envoyé. Cliquez sur le lien pour activer votre compte."
                    button={true}
                    messageButton="Fermer"
                />
            </Dialog>
        </div>
    );
}

export default SignUpForm;