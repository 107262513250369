import React, {useState} from 'react';
import {Avatar, FormControl, IconButton, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import FormButtons from "./Components/FormButtons";

// Css
import './FormFields.css';

const FormFields = ({
            nom,
            socialMediaList,
            setSocialMediaList,
            setFieldsChanged,
            profilePictureUrl,
            handleOpenDialogAttachement,
            setNom,
            prenom,
            setPrenom,
            phone,
            setPhone,
            mail,
            setMail,
            addresses,
            handleAddressChange,
            profilePhoto,
            handleProfilePhotoChange,
            setSocialMediaChange,
            webSiteUrl,
            setWebSiteUrl,
            logoUrl1,
            setLogoUrl1,
            profession,
            setProfession,
            gender,
            setGender,
            notes,
            setNotes,
            color,
            setColor,
        }) => {

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Common styles for the input fields
     */
    const commonStyles = {
        marginBottom: '9px',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const profilePictureTypes = process.env.REACT_APP_PROFILE_PICTURE_TYPES;

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const clearSelection = () => {
        setGender('NOT_SET');
        setFieldsChanged(true);
    };


    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <>
            <div className="ZoneLogo">
                {logoUrl1 &&
                    <img style={{height: 200}} src={logoUrl1} alt="Logo" className="imgLogo"/>
                }
                {!logoUrl1 &&
                    <div style={{height: '100px', backgroundColor: `${color}`}}></div>}
            </div>
            <div className="MyCardTopPage" style={{backgroundColor: `${color}`}}>
                <label htmlFor="profile-photo">
                    <Avatar
                        sx={{width: 70, height: 70, fontSize: 40, backgroundColor: '#3f51b5'}}
                        src={profilePhoto ? URL.createObjectURL(profilePhoto) : profilePictureUrl}
                    >
                        {!profilePhoto && !profilePictureUrl && '+'}
                    </Avatar>
                </label>
                <input
                    id="profile-photo"
                    type="file"
                    accept={profilePictureTypes}
                    style={{display: 'none'}}
                    onChange={handleProfilePhotoChange}
                />
                <FormButtons
                    handleOpenDialogAttachement={handleOpenDialogAttachement}
                    socialMediaList={socialMediaList}
                    setSocialMediaList={setSocialMediaList}
                    setSocialMediaChange={setSocialMediaChange}
                />
            </div>

            <div style={{width: '80%'}}>
                <div style={{marginBottom: 12}}>
                    Couleur de la carte : &nbsp;
                    <input type="color" value={color}
                           style={{width: 50, height: 20,
                               border: 'none', cursor: 'pointer'}}
                           onChange={(e) => {
                               setColor(e.target.value);
                               setFieldsChanged(true);
                           }}/>
                </div>
                <TextField
                    fullWidth
                    label="Nom"
                    variant="outlined"
                    value={nom}
                    onChange={(e) => {
                        setNom(e.target.value);
                        setFieldsChanged(true);
                    }}
                    sx={commonStyles}
                />
                <TextField
                    fullWidth
                    label="Prénom"
                    variant="outlined"
                    value={prenom}
                    onChange={(e) => {
                        setPrenom(e.target.value);
                        setFieldsChanged(true);
                    }}
                    sx={commonStyles}
                />
                <FormControl fullWidth variant="outlined" sx={{
                    marginBottom: '9px',
                }}>
                    <InputLabel>Genre</InputLabel>
                    <Select
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={gender}
                        onChange={(e) => {
                            setGender(e.target.value);
                            setFieldsChanged(true);
                        }}
                        label="Genre"
                        endAdornment={
                            <IconButton size="small" sx={{marginRight: 2}} onClick={clearSelection}>
                                X
                            </IconButton>
                        }
                    >
                        <MenuItem value="MALE">Monsieur</MenuItem>
                        <MenuItem value="FEMALE">Madame</MenuItem>
                        <MenuItem value="DIVERS">Divers</MenuItem>
                        <MenuItem value="OTHER">Autre</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    label="profession"
                    variant="outlined"
                    value={profession}
                    onChange={(e) => {
                        setProfession(e.target.value);
                        setFieldsChanged(true);
                    }}
                    sx={commonStyles}
                />
                <TextField
                    fullWidth
                    label="Télephone"
                    variant="outlined"
                    value={phone}
                    onChange={(e) => {
                        setPhone(e.target.value);
                        setFieldsChanged(true);
                    }}
                    sx={commonStyles}
                />
                <TextField
                    fullWidth
                    label="Mail"
                    variant="outlined"
                    value={mail}
                    onChange={(e) => {
                        setMail(e.target.value);
                        setFieldsChanged(true);
                    }}
                    sx={commonStyles}
                />
                <TextField
                    fullWidth
                    label="Url site web"
                    variant="outlined"
                    value={webSiteUrl}
                    onChange={(e) => {
                        setWebSiteUrl(e.target.value);
                        setFieldsChanged(true);
                    }}
                    sx={commonStyles}
                />
                <TextField
                    fullWidth
                    label="Url logo 1"
                    variant="outlined"
                    value={logoUrl1}
                    onChange={(e) => {
                        setLogoUrl1(e.target.value);
                        setFieldsChanged(true);
                    }}
                    sx={commonStyles}
                />
                <TextField
                    fullWidth
                    label={`Numéro de Rue`}
                    variant="outlined"
                    value={addresses ? addresses.streetNumber : ''}
                    onChange={(e) => {
                        handleAddressChange('streetNumber', e.target.value);
                        setFieldsChanged(true);
                    }}
                    sx={commonStyles}
                />
                <TextField
                    fullWidth
                    id={`street`}
                    sx={commonStyles}
                    className="inputFormTextCard"
                    label={`Rue`}
                    variant="outlined"
                    value={addresses ? addresses.street : ''}
                    onChange={(e) => {
                        handleAddressChange('street', e.target.value);
                        setFieldsChanged(true);
                    }}
                />
                <TextField
                    fullWidth
                    id={`city`}
                    sx={commonStyles}
                    className="inputFormTextCard"
                    label={`Ville`}
                    variant="outlined"
                    value={addresses ? addresses.city : ''}
                    onChange={(e) => {
                        handleAddressChange('city', e.target.value);
                        setFieldsChanged(true);
                    }}
                />
                <TextField
                    fullWidth
                    id={`zip`}
                    sx={commonStyles}
                    className="inputFormTextCard"
                    label={`Code Postal`}
                    variant="outlined"
                    value={addresses ? addresses.zip : ''}
                    onChange={(e) => {
                        handleAddressChange('zip', e.target.value);
                        setFieldsChanged(true);
                    }}
                />
                <TextField
                    fullWidth
                    id={`country`}
                    sx={commonStyles}
                    className="inputFormTextCard"
                    label={`Pays`}
                    variant="outlined"
                    value={addresses ? addresses.country : ''}
                    onChange={(e) => {
                        handleAddressChange('country', e.target.value);
                        setFieldsChanged(true);
                    }}
                />
                <TextField
                    fullWidth
                    id={`notes`}
                    sx={commonStyles}
                    className="inputFormTextCard"
                    label={`Notes`}
                    variant="outlined"
                    value={notes}
                    onChange={(e) => {
                        setNotes(e.target.value);
                        setFieldsChanged(true);
                    }}
                />
            </div>
        </>
    );
};
export default FormFields;