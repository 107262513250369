import React from 'react';
import {Button} from "@mui/material";

// Css
import './MessagePopup.css';

function MessagePopup(props) {

    /**************************************************************************
     * Styles
     *************************************************************************/

    /**
     * Style for the button
     */
    const buttonStyle = {
        color: 'white',
        backgroundColor: '#1c1c1b',
        padding: '12px 24px',
        fontSize: '16px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#424240',
        }
    };

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div className="popup-container">
            <h1 className="popup-title">{props.title}</h1>
            <p className="popup-message">
                {props.message}
            </p>
            {props.button && (
                <Button onClick={props.closePopup} sx={buttonStyle}>
                    {props.messageButton}
                </Button>
            )}
        </div>
    )
}

export default MessagePopup;