import React, {useEffect, useMemo, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import CURRENT_CARD_QUERY from "../../../services/api/queries/CURRENT_CARD_QUERY";
import GetAttachementQuery from "../../../services/api/queries/GetAttachementQuery";
import GetProfilePicture from "../../../services/api/fetch/GetProfilePicture";
import Address from "../../../models/Address";
import TopNavBar from "../../../components/navBar/topNavBar/TopNavBar";
import BottomNavBar from "../../../components/navBar/bottomNavBar/BottomNavBar";
import LoadingScreen from "../../../components/loadingScreen/LoadingScreen";
import SHARE_CARD_MUTATION from "../../../services/api/mutations/ShareCard_Mutation";
import VisitCard from "../../../components/visitCard/VisitCard";

// Css
import './MyCard.css';

function MyCard() {

    /**************************************************************************
     * Api calls
     *************************************************************************/

    /**
     * Get the current card data
     */
    const {loading: loadingCurrentCard, data: dataCurrentCard} = useQuery(CURRENT_CARD_QUERY);

    /**
     * Get the attachments
     */
    const {loading: loadingAttachment, data: dataAttachment} = useQuery(GetAttachementQuery, {
        variables: { principle: null },
    });
    /**
     * Share card mutation
     */
    const [shareCard] = useMutation(SHARE_CARD_MUTATION);

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Card data
     */
    const [cardData, setCardData] = useState({
        nom: '',
        prenom: '',
        phone: '',
        mail: '',
        address: null,
        profilePictureUrl: null,
        socialMedia: [],
        QRcodeUrl: '',
        listAttachment: [],
        website: '',
        logoUrl1: '',
        profession: '',
        gender: '',
        notes: '',
        color: 0
    });

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Create Address object from the current card data
     *
     * @type {Address}
     */
    const address = useMemo(() => {
        if (dataCurrentCard?.currentCard?.address) {
            const {
                addressType,
                city,
                country,
                state,
                street,
                street2,
                street3,
                streetNumber,
                zip
            } = dataCurrentCard.currentCard.address;
            return new Address(addressType || '', city || '', country || '', state || '', street || '', street2 || '', street3 || '', streetNumber || '', zip || '');
        }
        return null;
    }, [dataCurrentCard]);

    /**
     * Share card mutation and get the QR code
     *
     * @returns {Promise<void>}
     */
    const handleShareCard = async () => {
        try {
            const response = await shareCard({
                variables: {
                    permanentAttachments: [],
                    temporaryAttachments: []
                }
            });
            setCardData(prevData => ({
                ...prevData,
                QRcodeUrl: process.env.REACT_APP_LAETINE_APP_URL + "/ScannedCard?code=" + response.data.shareCard
            }));
        } catch (error) {
            console.error("Failed to share card:", error);
        }
    };

    /**************************************************************************
     * UseEffect
     *************************************************************************/

    /**
     * Get the current card data
     */
    useEffect(() => {
        const fetchData = async () => {
            let profilePictureUrl = cardData.profilePictureUrl;
            let listAttachment = cardData.listAttachment;
            let newCardData = {};

            if (dataCurrentCard?.currentCard?.profilePicture) {
                let response = await GetProfilePicture(localStorage.getItem('username'));
                if (response) {
                    profilePictureUrl = response.url;
                }
            }

            if (dataAttachment && dataAttachment.attachments) {
                listAttachment = dataAttachment.attachments;
            }

            if (!loadingCurrentCard && dataCurrentCard && dataCurrentCard.currentCard) {
                const {lastName, firstName, phone, email, socialMedia, website} = dataCurrentCard.currentCard;
                newCardData = {
                    nom: lastName || '',
                    prenom: firstName || '',
                    phone: phone ? phone[0].number : '',
                    mail: email ? email[0].address : '',
                    address,
                    profilePictureUrl,
                    socialMedia: socialMedia || [],
                    listAttachment,
                    websiteUrl: website ? website.url : '',
                    logoUrl1: dataCurrentCard.currentCard.logoURL1,
                    profession: dataCurrentCard.currentCard.profession || '',
                    gender: dataCurrentCard.currentCard.gender || '',
                    notes: dataCurrentCard.currentCard.notes || '',
                    color: dataCurrentCard.currentCard.color || 0
                };
            }
            setCardData(prevData => ({...prevData, ...newCardData}));
        };
        fetchData();
        if (cardData.QRcodeUrl === '') {
            handleShareCard();
        }
    }, [dataCurrentCard, dataAttachment, loadingCurrentCard, address, cardData.profilePictureUrl, cardData.QRcodeUrl]);

    /**************************************************************************
     * Render
     *************************************************************************/

    // Loading screen
    if (loadingCurrentCard || loadingAttachment) return <LoadingScreen/>;

    // Main render
    return (
        <div>
            <TopNavBar numbar={1}/>
            <VisitCard cardData={cardData} attachement={true} qrcode={true} myCard={true}/>
            <BottomNavBar/>
        </div>
    );
}

export default MyCard;