import {useLocation, useNavigate} from "react-router-dom";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Divider from '@mui/material/Divider';
import Logout from "../../../services/api/fetch/Logout";
import {useState} from "react";

// Css
import "./ListSettings.css";

function ListSettings() {

    /**************************************************************************
    * Variables
    *************************************************************************/

    /**
     * Navigate to another page
     */
    const navigate = useNavigate();

    /**
     * Get current location
     */
    const location = useLocation();

    /**************************************************************************
    * Functions
    *************************************************************************/

    /**
     * Redirect to card create page
     */
    const redirectCardCreate = () => {
        if (location.pathname !== "/home/myCardCreate") {
            navigate("/home/myCardCreate");
        }
    };

    /**************************************************************************
    * Dialog
    *************************************************************************/

    /**
     * Dialog state
     */
    const [openDialog, setOpenDialog] = useState(false);

    /**
     * Open the dialog
     */
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const disconnect = () => {
        console.log("refresh_token", localStorage.getItem("refresh_token"));
        console.log("token", localStorage.getItem("token"));
        Logout();
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("expires_in");
        navigate("/");
    }

    return (
        <div>
            <Divider/>
            <div className="CellSettings" onClick={redirectCardCreate}>
                <div>Modifer ma carte</div>
                <div>></div>
            </div>
            <Divider/>
            <div className="CellSettings" onClick={handleOpenDialog}>
                <div>Déconnexion</div>
                <div>></div>
            </div>
            <Divider/>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>
                    {"Confirmer la déconnexion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Êtes-vous sûr de vouloir vous déconnecter ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>
                        Annuler
                    </Button>
                    <Button onClick={disconnect} color="primary" autoFocus>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ListSettings;