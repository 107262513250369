import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, IconButton, TextField, Typography, CircularProgress } from '@mui/material';
import { Add as AddIcon, Close as CloseIcon, Delete as DeleteIcon } from '@mui/icons-material';
import sendFile from "../../../../../services/api/fetch/SendFile";
import { useMutation, useQuery } from "@apollo/client";
import LIST_ATTACHMENTS from "../../../../../services/api/queries/ListAttachement_query";
import DELETE_ATTACHMENT from "../../../../../services/api/mutations/DeleteAttachement";
import GetAttachement from "../../../../../services/api/fetch/GetAttachement";

// Css
import './FileUploadDialog.css';

const FileUploadDialog = ({ open, handleClose, isTemp }) => {

    /**************************************************************************
     * Api calls
     *************************************************************************/

    /**
     * Get the attachments
     */
    const { loading, data, refetch } = useQuery(LIST_ATTACHMENTS);

    /**
     * Delete attachment mutation
     */
    const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);

    /**************************************************************************
     * Variables
     *************************************************************************/

    /**
     * Files state
     */
    const [files, setFiles] = useState([]);

    /**
     * File input ref
     */
    const fileInputRef = useRef(null);

    /**
     * Attachment types
     */
    const attachementTypes = process.env.REACT_APP_ATTACHMENT_TYPES;

    /**
     * Attachment urls
     */
    const [attachmentUrls, setAttachmentUrls] = useState([]);

    /**************************************************************************
     * Functions
     *************************************************************************/

    /**
     * Handle add file
     */
    const handleAddFile = () => {
        setFiles([...files, { file: null, description: '', fileName: '' }]);
        fileInputRef.current.click();
    };

    /**
     * Handle remove file
     *
     * @param index
     * @returns {Promise<void>}
     */
    const handleRemoveFile = async (index) => {
        const fileToRemove = files[index];
        if (fileToRemove.id) {
            await deleteAttachment({ variables: { id: fileToRemove.id } });
            await refetch();
        }
        setFiles(files.filter((_, i) => i !== index));
    };

    /**
     * Handle file change
     *
     * @param e
     */
    const handleFileChange = (e) => {
        const newFiles = [...files];
        const file = e.target.files[0];
        newFiles[newFiles.length - 1] = { file, description: '', fileName: file.name };
        setFiles(newFiles);
    };

    /**
     * Handle description change
     *
     * @param e
     * @param index
     */
    const handleDescriptionChange = (e, index) => {
        const newFiles = [...files];
        newFiles[index].description = e.target.value;
        setFiles(newFiles);
    };

    /**
     * Send the file to the server
     * @returns {Promise<void>}
     */
    const handleSave = async () => {
        for (const file of files) {
            if (file.file) {
                await sendFile(file.file, file.description, "COMMON", isTemp);
            }
        }
        await refetch();
        handleClose();
    };

    /**************************************************************************
     * UseEffect
     *************************************************************************/

    // Set the files when the data is loaded
    useEffect(() => {
        if (data) {
            setFiles(data.attachments.map(attachment => ({
                file: null,
                description: attachment.description,
                fileName: attachment.fileName,
                id: attachment.id,
                url: GetAttachement(attachment.id)
            })));
        }
    }, [data]);

    // Set the attachment urls
    useEffect(() => {
        if (data) {
            const fetchUrls = async () => {
                const urls = await Promise.all(data.attachments.map(async attachment => {
                    const response = await GetAttachement(attachment.id);
                    return response.url;
                }));
                setAttachmentUrls(urls);
            };
            fetchUrls();
        }
    }, [data]);

    /**************************************************************************
     * Render
     *************************************************************************/

    // Loading
    if (loading) return <CircularProgress />;

    // Main render
    return (
        <Dialog fullScreen={true} onClose={handleClose} open={open}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: '1px solid #ddd' }}>
                <Typography variant="h6">Upload Files Temp</Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddFile}
                    startIcon={<AddIcon />}
                    sx={{ mb: 2 }}
                >
                    Add File
                </Button>
                <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept={attachementTypes}
                />
                {files.map((file, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, border: '1px solid #ddd', p: 2 }}>
                        <div>
                            <Typography sx={{ ml: 2 }}>{file.fileName || 'No file chosen'}</Typography>
                            <TextField
                                variant="outlined"
                                value={file.description}
                                onChange={(e) => handleDescriptionChange(e, index)}
                                label="Description"
                                sx={{ ml: 2, mt: 1 }}
                                fullWidth
                            />
                            {file.url && (
                                <a href={attachmentUrls[index]} target="_blank" rel="noreferrer">Download</a>
                            )}
                        </div>
                        <IconButton onClick={() => handleRemoveFile(index)} color="error" sx={{ ml: 2 }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                ))}
                <Box sx={{ textAlign: 'center', mt: 3 }}>
                    <Button variant="contained" color="success" onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default FileUploadDialog;
