// services/security/isGranted.js

export default function isGranted(roles) {
    const rolesString = localStorage.getItem('roles');
    const userRoles = rolesString ? JSON.parse(rolesString) : [];

    if (roles.length > 0 && userRoles.length === 0) {
        return false;
    }

    return roles.every(role => userRoles.includes(role));
}
