/**
 * Send a request to the server to register a new user
 */
const SetPassword = async (user, token, password) => {

    /**
     * URL of the server
     *
     * @type {string}
     */
    const SERVER_URL = process.env.REACT_APP_LAETINE_API_SERVER_URL + '/api/v2/user/' + user + '/setpassword';

    /**
     * Request body
     */
    const requestBody = {
        token: token,
        password: password,
    };

    // Send the request to the server
    try {
        const response = await fetch(SERVER_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });
        if (!response.status === 201) {
            console.error('Erreur lors de la requête :', response);
        }
        return response;
    } catch (error) {
        console.error('Erreur lors de la requête :', error);
        throw error;
    }
};
export default SetPassword;