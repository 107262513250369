import TopNavBar from "../../../components/navBar/topNavBar/TopNavBar";
import BottomNavBar from "../../../components/navBar/bottomNavBar/BottomNavBar";
import QrcodeScanner from "./QrcodeScanner/QrcodeScanner";

function Scanner() {

    /**************************************************************************
     * Render
     *************************************************************************/

    // Main render
    return (
        <div>
            <TopNavBar title="Qrcode Scanner" />
            <div className="QRScannerContainer">
                <QrcodeScanner />
            </div>
            <BottomNavBar />
        </div>
    );
}

export default Scanner;
